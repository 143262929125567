<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9994;">
        <md-content>
            <div class="page-content" style="min-height: 750px; max-height: 90vh;">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 's']" @shortkey="submit()" @click="submit()" class="md-raised md-primary">Lưu<md-tooltip>Lưu</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <upload ref="upload" :updateFileInParent="updateFile"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import upload from '../../../components/Upload.vue';
    import orderService from '../../../api/orderService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default ({
        components: {
            upload
        },
        data() {
            return {
                showDialog: false,
                obj: { },
                files: [],
                title: ''
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            updateFile(files){
                this.files = files;
            },

            submit(){
                orderService.updateFiles(this.obj.id, this.files).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => {  });
            },

            getFiles(){
                this.setLoading(true);
                orderService.getFiles(this.obj.id).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data !== null) {
                            this.$refs.upload.loadData(response.data);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            open(obj){
                this.title = "Upload file cont/kiện hàng " + obj.serial;
                this.obj = obj;
                this.showDialog = true;
                this.files = [];
                this.getFiles();
            },

            close(){
                this.showDialog = false;
            }
        }
    })
</script>


<style lang="css" scoped>
    .md-content {
        max-width: 800px;
        width: 800px;
        min-height: 500px;
        max-height: 90vh;
    }
</style>
