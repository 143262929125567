<template>
    <div>
        <div class="row">
            <div class="col l-3 m-3 c-12">
                <md-field>
                    <label for="bookingNo">Số booking</label>
                    <md-input name="bookingNo" v-model="entity.bookingNo" ></md-input>
                </md-field>
                <md-field>
                    <label for="masterBill">Master bill</label>
                    <md-input name="masterBill" v-model="entity.masterBill" ></md-input>
                </md-field>
                <md-field>
                    <label for="houseBill">House bill</label>
                    <md-input name="houseBill" v-model="entity.houseBill" ></md-input>
                </md-field>
            </div>
            <div class="col l-3 m-3 c-12">
                <div class="form-control">
                    <md-autocomplete v-model="shipName" @md-selected="getShipSelected" :md-options="ships" @md-changed="getShips"  @md-opened="getShips">
                        <label>Hãng tàu</label>
                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                            <md-highlight-text :md-term="term">{{ item.companyName }}</md-highlight-text>
                        </template>
                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                            "{{ term }}" Không tìm thấy!
                        </template>
                    </md-autocomplete>
                    <md-button @click="openShip()" class="md-icon-button">
                        <md-icon>manage_search</md-icon>
                        <md-tooltip>Tìm hãng tàu</md-tooltip>
                    </md-button>
                </div>

                <md-field>
                    <label for="shipName">Tên tàu</label>
                    <md-input name="shipName" v-model="entity.shipName" ></md-input>
                </md-field>

                <md-field>
                    <label for="shipNo">Số chuyến</label>
                    <md-input name="shipNo" v-model="entity.shipNo" ></md-input>
                </md-field>
            </div>
            <div class="col l-3 m-3 c-12">
                <md-datepicker v-model="entity.dateOfShipCome" md-immediately :md-model-type="String">
                    <label>Ngày tàu đến</label>
                </md-datepicker>

                <md-datepicker v-model="entity.dateOfShipGo" md-immediately :md-model-type="String">
                    <label>Ngày tàu đi</label>
                </md-datepicker>
                <div class="row">
                    <div class="col l-6 m-6 c-12">
                        <md-field>
                            <label for="contactName">Người liên hệ</label>
                            <md-input name="contactName" v-model="entity.contactName" ></md-input>
                        </md-field>
                    </div>
                    <div class="col l-6 m-6 c-12">
                        <md-field>
                            <label for="contactPhone">Số điện thoại</label>
                            <md-input name="contactPhone" v-model="entity.contactPhone"></md-input>
                        </md-field>
                    </div>
                </div>
            </div>
            <div class="col l-3 m-3 c-12">
                <div class="row">
                    <div class="col l-8 m-8 c-12">
                        <md-datepicker v-model="entity.cutoffDate" md-immediately :md-model-type="String">
                            <label>CUT OFF</label>
                        </md-datepicker>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-field :class="{'md-invalid': submitted && (entity.cutoffTime.split(':')[0] > 23 || entity.cutoffTime.split(':')[1] > 59) }">
                            <label for="cutoffTime">Giờ CUTOFF</label>
                            <md-input name="cutoffTime" v-mask="'##:##'" placeholder="HH:mm" v-model="entity.cutoffTime"></md-input>
                            <span class="md-error" v-if="submitted && (entity.cutoffTime.split(':')[0] > 23 || entity.cutoffTime.split(':')[1] > 59)">Sai định dạng</span>
                        </md-field>
                    </div>
                </div>
                <md-datepicker v-model="entity.commandDate" md-immediately :md-model-type="String">
                    <label>Hạn lệnh</label>
                </md-datepicker>
                <md-datepicker v-model="entity.emptyDate" md-immediately :md-model-type="String">
                    <label>Hạn rỗng</label>
                </md-datepicker>
            </div>
        </div>
        <div class="row">
            <div class="col l-3 m-3 c-12">
                <md-field>
                    <label for="numberDem">Số ngày miễn lưu DEM</label>
                    <md-input name="numberDem" v-model="entity.numberDem" type="number" ></md-input>
                </md-field>
            </div>
            <div class="col l-3 m-3 c-12">
                <md-field>
                    <label for="numberDet">Số ngày miễn lưu DET</label>
                    <md-input name="numberDet" v-model="entity.numberDet" type="number" ></md-input>
                </md-field>
            </div>
        </div>
        <div class="row">
            <div style="display: flex; justify-content: start; width: 100%; padding-top: 20px;">
                <md-button @click="submit(1)" class="md-raised md-primary"><span>L</span>ưu bill/booking<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
            </div>
        </div>
        <shipList ref="shipList" title="hãng tàu" :type="companyType" @close="closeShip"/>
    </div>
</template>

<script>
    import shipList from '../../../components/popup/_CompanyList.vue';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import messageBox from '../../../utils/messageBox';
    import companyService from '../../../api/companyService';
    import bookingService from '../../../api/bookingService';

    export default ({
        components: {
            shipList
        },
        data() {
            return {
                submitted: false,
                loadding: false,
                companyType: common.companyType.shipping,
                orderId: 0,
                shipName: '',
                ships: [],
                timeOnly: null,
                entity: { id: 0, orderId: 0, numberDem: null, numberDet: null, shipId: null, shipName: '', shipNo: '', bookingNo: '', masterBill: '', houseBill: '', contactName: '', contactPhone: '', dateOfShipCome: '', dateOfShipGo: '', cutoffDate: null, cutoffTime: null, emptyDate: null, commandDate: null },
            }
        },
        created(){
            this.orderId = this.$route.params.id;
            if(this.orderId > 0){
                this.getByOrderId();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
                this.submitted = true;
                this.entity.orderType = 1;
                this.entity.orderId = this.$route.params.id;
                if(this.entity.cutoffTime !== '' && this.entity.cutoffTime !== null){
                    if(this.entity.cutoffTime.split(':')[0] > 23 || this.entity.cutoffTime.split(':')[1] > 59){
                        return;
                    }
                }
                if(this.entity.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                bookingService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                bookingService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByOrderId(){
                this.setLoading(true);
                bookingService.getByOrderId(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data !== null){
                            this.entity = response.data;
                            if(this.entity.shipId > 0){
                                this.shipName = response.data.ship.companyAlias;
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Ship
            closeShip(item){
                this.shipName = item.companyAlias;
                this.entity.shipId = item.id;
                this.$refs.shipList.close();
            },

            getShipSelected(val){
                this.entity.shipId = val.id;
                this.shipName = val.companyAlias;
            },

            openShip(){
                this.$refs.shipList.open();
            },

            getShips(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.shipping, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.ships = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            }
        },
        watch: { 
            shipName: function (val) { 
                if(val == ''){
                    this.entity.shipId = 0;
                }
            }
        }
    })
</script>