<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 's']" @shortkey="submit()" @click="submit()" class="md-raised md-primary">Lưu<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                            <label>Nhân viên phụ trách<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                        </md-autocomplete>
                                        <md-button @click="openStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.refundCode.$error }">
                                        <label for="code">Mã phiếu<span class="label-require">(*)</span></label>
                                        <md-input disabled name="code" v-model="entity.refundCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.refundCode.required">Vui lòng nhập mã phiếu</span>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-datepicker v-model="entity.refundDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.refundDate.$error }">
                                        <label>Ngày tạo<span class="label-require">(*)</span></label>
                                        <span class="md-error" v-if="submitted && !$v.entity.refundDate.required">Vui lòng chọn ngày tạo</span>
                                    </md-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="companyName" @md-selected="getTransportSelected" :md-options="companies" @md-changed="getTransports"  @md-opened="getTransports" :class="{'md-invalid': submitted && $v.entity.companyId.$error }">
                                            <label>Vendor thanh toán<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.companyId.isSelected">Vui lòng chọn vendor</span>
                                        </md-autocomplete>
                                        <md-button @click="openTransport()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="note">Ghi chú</label>
                                        <md-input name="note" v-model="entity.note"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="pay">Tổng phải trả</label>
                                        <md-input name="pay" :value="formatNumber(entity.pay)" ></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-detail" class="tab-content" md-label="Thống kê chi phí">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="list" style="overflow-x: auto;">
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th class="left" style="width:15%;">Con't/Kiện</th>
                                                                        <th class="left" style="width:15%;">Tên phí</th>
                                                                        <th style="width:12%;">Loại phí</th>
                                                                        <th style="width:10%;">Đơn giá</th>
                                                                        <th style="width:8%;">VAT</th>
                                                                        <th style="width:10%;">Thành tiền</th>
                                                                        <th class="left" style="width:10%;">Số HĐ</th>
                                                                        <th style="width:10%;">Ngày HĐ</th>
                                                                        <th style="width:140px;">Ghi chú</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="left">
                                                                            <span v-if="item.orderDetail !== null">{{ item.orderDetail.serial }}</span>
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ item.fee.feeName }}
                                                                        </td> 
                                                                        <td class="left">
                                                                            {{ getFeeType(item.type) }}
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.price) }}
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.vat) }}%
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.amount) }}
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ item.invoiceNumber }}
                                                                        </td>
                                                                        <td class="center">
                                                                            {{ item.invoiceDate }}
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ item.note }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <staffList ref="staffList" @close="closeStaff"/>
                <feeList ref="feeList" />
                <companyList ref="companyList" title="công ty" :type="companyType" @close="closeTransport"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import staffList from '../../../components/popup/_StaffList.vue';
import feeList from '../../../components/popup/_FeeList.vue';
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import staffService from '../../../api/staffService';
import companyService from '../../../api/companyService';
import refundService from '../../../api/refundService';
import companyList from '../../../components/popup/_CompanyList.vue';

export default ({
    components: {
        staffList,
        feeList,
        companyList
    },
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            submitted: false,
            loading: false,
            staffName: '',
            staffs: [],
            numberF: common.numberF,
            vatF: common.vatF,
            currencyF: common.currencyF2,
            types: common.typeFee,
            companyType: 0,
            order: null,
            companyName: '',
            companies: [],
            entity: { id: 0, companyId: 0, orderId: 0, order: { }, details: [], refundCode: '', refundDate: common.dateNow, staffId: 0, deptId: 0, status: 0, pay: 0 },
        }
    },
    created(){
        this.entity.orderId = parseFloat(this.$route.params.id);
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        getFeeTransport(){
            if(this.entity.companyId == 0){
                messageBox.showInfo("Vui lòng chọn vendor");
            }
            else{
                this.loading = true;
                this.entity.details = [];
                refundService.getAllDetailByVendor(this.$route.params.id, this.entity.companyId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.details = response.data;
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loading = false; });
            }
        },

        open(id){
            this.submitted = false;
            this.showDialog = true;
            if(id > 0){
                this.id = id;
                this.getById();
                this.title = "Cập nhật phải trả vendor";
            }
            else{
                this.companyName = '';
                this.entity.details = [];
                this.entity = { id: 0, companyId: 0, orderId: 0, order: { }, details: [], refundCode: '', refundDate: common.dateNow, staffId: 0, deptId: 0, status: 0, pay: 0, payTransport: 0, payService: 0, payOnbehalf: 0 };
                this.getByAccount();
                this.title = "Thêm mới phải trả vendor";
            }
        },

        close(){
            this.showDialog = false;
        },

        submit(){
            this.entity.orderId = parseFloat(this.$route.params.id);
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            
            if(this.entity.details.length <= 0){
                messageBox.showWarning("Vui lòng thêm chi tiết phải trả");
                return;
            }
            else{
                this.entity.details.forEach(function(item, index, self) {
                    self[index].type = parseInt(self[index].type);
                });
            }
            if(this.id > 0){
                this.edit();
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            refundService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            refundService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            refundService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.staffName = response.data.staff.fullName;
                    this.companyName = this.entity.company.companyAlias;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        calSummary(){
            this.entity.pay = 0;
            for (var i = 0; i < this.entity.details.length; i++) {
                this.entity.pay = this.entity.pay + parseFloat(this.entity.details[i].amount);
            }
        },

        closeTransport(item){
            this.companyName = item.companyAlias;
            this.entity.companyId = item.id;
            this.getFeeTransport();
            this.$refs.companyList.close();
        },

        getTransportSelected(val){
            this.entity.companyId = val.id;
            this.getFeeTransport();
            this.companyName = val.companyAlias;
        },

        openTransport(){
            this.$refs.companyList.open(10);
        },

        getTransports(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.transport,  code: searchTerm };
            companyService.getCompanies(search).then((response) => {
                if(response.statusCode == 200){
                    this.companies = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },
        
        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.currentUser = response.data;
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getCode(staffId){
            this.setLoading(true);
            refundService.getCode(staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.refundCode = response.data.code;
                    this.entity.refundNumber = response.data.number;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Sales
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.getCode(item.id);
            this.$refs.staffList.close();
        },

        getStaffSelected(item){
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.staffName = item.fullName;
            this.getCode(item.id);
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },
    },
    watch: { 
        staffName: function (val) { 
            if(val == ''){
                this.entity.staffId = 0;
            }
        },
        companyName: function (val) { 
            if(val == false){
                this.companyName = '';
                this.entity.companyId = 0;
                this.entity.details = [];
            }
        },
    },
    validations: {
        entity: {
            refundCode: { required },
            refundDate: { required },
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            companyId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            }
        }
    }
})
</script>

<style lang="css" scoped>
    .md-content {
        max-width: 1200px;
        width: 1200px;
    }
</style>
