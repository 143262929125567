<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 's']" @shortkey="submit()" @click="submit()" class="md-raised md-primary">Lưu<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-3 m-3 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                            <label>Nhân viên phụ trách<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                        </md-autocomplete>
                                        <md-button @click="openStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.revenueCode.$error }">
                                        <label for="code">Mã phiếu<span class="label-require">(*)</span></label>
                                        <md-input disabled name="code" v-model="entity.revenueCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.revenueCode.required">Vui lòng nhập mã phiếu</span>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.monthYear.$error }">
                                        <label for="monthYear">Tháng/năm</label>
                                        <md-input name="monthYear" v-mask="'##/####'" placeholder="MM/YYYY" v-model="entity.monthYear"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.monthYear.required">Vui lòng nhập tháng-năm phải thu</span>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-datepicker v-model="entity.revenueDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.revenueDate.$error }">
                                        <label>Ngày tạo<span class="label-require">(*)</span></label>
                                        <span class="md-error" v-if="submitted && !$v.entity.revenueDate.required">Vui lòng chọn ngày tạo</span>
                                    </md-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="note">Nội dung</label>
                                        <md-input name="note" v-model="entity.note"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-8 m-8 c-12">
                                    <div style="display:flex; width: 100%;">
                                        <div style="width: 25%;">
                                            <md-field>
                                                <label for="note">Tổng phải trả</label>
                                                <md-input name="note" :value="formatNumber(entity.pay)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 25%; margin-left: 5px;">
                                            <md-field>
                                                <label for="note">Phí vận chuyển</label>
                                                <md-input name="note" :value="formatNumber(entity.payTransport)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 25%; margin-left: 5px;">
                                            <md-field>
                                                <label for="note">Phí dịch vụ</label>
                                                <md-input name="note" :value="formatNumber(entity.payService)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 25%; margin-left: 5px;">
                                            <md-field>
                                                <label for="note">Chi hộ</label>
                                                <md-input name="note" :value="formatNumber(entity.payOnbehalf)" ></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-button @click="getAllFee()" class="md-primary"><md-icon>paid</md-icon> Lấy chi phí<md-tooltip>Lấy chi phí</md-tooltip></md-button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-transport" class="tab-content" md-label="Dịch vụ">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="addRowFee(3)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                        </div>
                                                        <div class="list" style="overflow-x: auto; width: 100%;">
                                                            <table class="data-table-2" style="width: 2400px !important;"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th style="width:50px;">Hành động</th>
                                                                        <th class="left" style="width:150px;">Cont/Kiện</th>
                                                                        <th class="left" style="width:120px;">Loại hình</th>
                                                                        <th class="left" style="width:120px;">Loại hàng</th>
                                                                        <th class="left" style="width:150px;">Đơn vị</th>
                                                                        <th class="left" style="width:250px;">Tên phí</th>
                                                                        <th style="width:170px;">Loại phí</th>
                                                                        <th style="width:100px;">Số lượng</th>
                                                                        <th style="width:120px;">Đơn giá</th>
                                                                        <th style="width:80px;">VAT(%)</th>
                                                                        <th style="width:100px;">VAT</th>
                                                                        <th style="width:120px;">Thành tiền</th>
                                                                        <th style="width:120px;">Số HĐ</th>
                                                                        <th style="width:150px;">Ngày HĐ</th>
                                                                        <th style="width:150px;">MST</th>
                                                                        <th style="width:150px;">Ký hiệu HĐ</th>
                                                                        <th style="width:150px;">Loại HĐ</th>
                                                                        <th style="width:150px;">Ghi chú</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(item, index) in entity.details.filter((obj) => obj.type == 3 || obj.type == 5)" :key="'detail-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="grid-action">
                                                                            <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ item.orderDetail.serial }}
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select v-model="item.typeOfTransport" name="typeOfTransport" id="typeOfTransport">
                                                                                    <md-option v-for="item in typeOfTransportList" :key="'typeOfTransport-' + item.id" :value="item.id">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select v-model="item.goodsTypeId" name="goodsTypeId" id="goodsTypeId">
                                                                                    <md-option v-for="item in goodsTypeList" :key="'goodsTypeId-' + item.id" :value="item.id">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="item.unit.unitName" class="form-control" type="text">
                                                                                <md-button @click="openUnit(item.id)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm đơn vị</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="item.fee.feeName" :class="{'is-error': $v.entity.details.$each[index].feeId.$error}" class="form-control" type="text">
                                                                                <md-button @click="openFee(item.id)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select @md-selected="calSum(item)" v-model="item.type" name="type" id="type">
                                                                                    <md-option v-for="item in types.filter((obj) => obj.value == 3 || obj.value == 5)" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" :class="{'is-error': $v.entity.details.$each[index].quantity.$error}" v-model="item.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.price" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumVat(item)" v-model="item.vatAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="item.amount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.invoiceNumber" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <md-datepicker v-model="item.invoiceDate" md-immediately :md-model-type="String"></md-datepicker>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.taxCode" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.invoiceSerial" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.invoiceType" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.note" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                        <md-tab id="tab-order-fee" class="tab-content" md-label="Chi hộ">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="addRowFee(2)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                        </div>
                                                        <div class="list" style="overflow-x: auto; width: 100%;">
                                                            <table class="data-table-2" style="width: 2400px !important;"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th style="width:50px;">Hành động</th>
                                                                        <th class="left" style="width:150px;">Cont/Kiện</th>
                                                                        <th class="left" style="width:150px;">Loại hình</th>
                                                                        <th class="left" style="width:150px;">Loại hàng</th>
                                                                        <th class="left" style="width:150px;">Đơn vị</th>
                                                                        <th class="left" style="width:250px;">Tên phí</th>
                                                                        <th style="width:150px;">Loại phí</th>
                                                                        <th style="width:150px;">Số lượng</th>
                                                                        <th style="width:150px;">Đơn giá</th>
                                                                        <th style="width:100px;">VAT(%)</th>
                                                                        <th style="width:100px;">VAT</th>
                                                                        <th style="width:150px;">Thành tiền</th>
                                                                        <th style="width:150px;">Số HĐ</th>
                                                                        <th style="width:150px;">Ngày HĐ</th>
                                                                        <th style="width:150px;">MST</th>
                                                                        <th style="width:150px;">Ký hiệu HĐ</th>
                                                                        <th style="width:150px;">Loại HĐ</th>
                                                                        <th style="width:220px;">Ghi chú</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(item, index) in entity.details.filter((obj) => obj.type == 2 || obj.type == 8)" :key="'detail-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="grid-action">
                                                                            <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ item.orderDetail.serial }}
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field v-if="item.type == 2">
                                                                                <md-select v-model="item.typeOfTransport" name="typeOfTransport" id="typeOfTransport">
                                                                                    <md-option v-for="item in typeOfTransportList" :key="'typeOfTransport-' + item.id" :value="item.id">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                            <div v-else style="text-align: left; padding-left: 10px;">{{ getTypeOfTransport(item.typeOfTransport) }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <md-field v-if="item.type == 2">
                                                                                <md-select v-model="item.goodsTypeId" name="goodsTypeId" id="goodsTypeId">
                                                                                    <md-option v-for="item in goodsTypeList" :key="'goodsTypeId-' + item.id" :value="item.id">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                            <div v-else style="text-align: left; padding-left: 10px;">{{ getGoodsType(item.goodsTypeId) }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div v-if="item.type == 2" class="grid-control">
                                                                                <input v-model="item.unit.unitName" class="form-control" type="text">
                                                                                <md-button @click="openUnit(item.id)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm đơn vị</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                            <div v-else style="text-align: left; padding-left: 10px;">{{ item.unit.unitName }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div v-if="item.type == 2" class="grid-control">
                                                                                <input v-model="item.fee.feeName" :class="{'is-error': $v.entity.details.$each[index].feeId.$error}" class="form-control" type="text">
                                                                                <md-button @click="openFee(item.id)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                            <div v-else style="text-align: left; padding-left: 10px;">{{ item.fee.feeName }}</div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <md-field v-if="item.type == 2">
                                                                                <md-select @md-selected="calSum(item)" v-model="item.type" name="type" id="type">
                                                                                    <md-option v-for="item in types.filter((obj) => obj.value == 2)" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                            <div v-else style="text-align: center; padding-left: 10px;">{{ getFeeType(item.type) }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div v-if="item.type == 2" class="group-control">
                                                                                <number @change="calSum(item)" :class="{'is-error': $v.entity.details.$each[index].quantity.$error}" v-model="item.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                            <div v-else style="text-align: center; padding-left: 10px;">{{ item.quantity }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div v-if="item.type == 2" class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.price" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                            <div v-else style="text-align: right; padding-left: 10px;">{{ formatNumber(item.price) }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div v-if="item.type == 2" class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                            <div v-else style="text-align: center; padding-left: 10px;">{{ formatNumber(item.vat) }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div v-if="item.type == 2" class="group-control">
                                                                                <number @change="calSumVat(item)" v-model="item.vatAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                            <div v-else style="text-align: center; padding-left: 10px;">{{ formatNumber(item.vatAmount) }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div v-if="item.type !== 2" class="group-control">
                                                                                <number :disabled="true" v-model="item.amount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                            <div v-else style="text-align: right; padding-left: 10px;">{{ formatNumber(item.amount) }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div v-if="item.type == 2" class="group-control">
                                                                                <input v-model="item.invoiceNumber" class="form-control" type="text">
                                                                            </div>
                                                                            <div v-else style="text-align: center; padding-left: 10px;">{{ item.invoiceNumber }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div v-if="item.type == 2" class="group-control">
                                                                                <md-datepicker v-model="item.invoiceDate" md-immediately :md-model-type="String"></md-datepicker>
                                                                            </div>
                                                                            <div v-else style="text-align: center;">{{ item.invoiceDate }}</div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.taxCode" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.invoiceSerial" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.invoiceType" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.note" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <staffList ref="staffList" @close="closeStaff"/>
                <feeList ref="feeList" @close="closeFee"/>
                <unitList ref="unitList" @close="closeUnit"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import staffList from '../../../components/popup/_StaffList.vue';
import feeList from '../../../components/popup/_FeeList.vue';
import unitList from '../../../components/popup/_UnitList.vue';
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import staffService from '../../../api/staffService';
import revenueService from '../../../api/revenueService';

export default ({
    components: {
        staffList,
        feeList,
        unitList
    },
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            submitted: false,
            loading: false,
            staffName: '',
            staffs: [],
            numberF: common.numberF,
            vatF: common.vatF,
            currencyF: common.currencyF2,
            types: common.typeFee,
            companyType: 0,
            order: null,
            companyName: '',
            companies: [],
            goodsTypeList: common.goodsTypeList,
            typeOfTransportList: common.typeOfTransport,
            entity: { id: 0, companyId: 0, orderId: 0, order: { }, details: [], revenueCode: '', revenueDate: common.dateNow, staffId: 0, debtId: 0, status: 0, pay: 0, payTransport: 0, payService: 0, payOnbehalf: 0 },
            detail: { id: 'id_' + common.getFakeId(), orderId: 0 },
        }
    },
    created(){
        this.entity.orderId = parseFloat(this.$route.params.id);
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        getAllFee(){
            this.loading = true;
            this.entity.details = [];
            revenueService.getFeeOnbehalf(this.$route.params.id).then((response) => {
                if(response.statusCode == 200){
                    let i = 1;
                    response.data.map((item) => {
                        let obj = { 
                            id: this.uuidv4(i),
                            objectId: item.objectId,
                            orderId: this.$route.params.id,
                            orderDetailId: item.orderDetailId,
                            orderDetail: item.orderDetail,
                            typeOfTransport: item.typeOfTransport,
                            goodsTypeId: item.goodsTypeId,
                            feeId: item.feeId,
                            fee: item.fee,
                            unit: item.unit,
                            unitId: item.unitId,
                            type: item.type,
                            quantity: item.quantity,
                            vat: item.vat,
                            vatAmount: (item.quantity * item.price) * (item.vat / 100),
                            price: item.price,
                            amount: item.amount,
                            invoiceNumber: item.invoiceNumber,
                            invoiceDate: item.invoiceDate,
                            revenueType: item.revenueType,
                            note: item.note
                        };
                        i++;
                        this.entity.details.push(obj);
                    });
                    this.calSummary();
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {this.loading = false; });
        },

        open(id){
            this.submitted = false;
            this.showDialog = true;
            if(id > 0){
                this.id = id;
                this.getById();
                this.title = "Cập nhật phải thu khách hàng";
            }
            else{
                this.companyName = '';
                this.entity.details = [];
                let monthYear = '' + common.monthNow + '/' + common.yearNow;
                if(common.monthNow < 10){
                    monthYear = '0' + common.monthNow + '/' + common.yearNow
                }
                this.entity = { id: 0, orderId: 0, monthYear: monthYear, order: { }, details: [], revenueCode: '', revenueDate: common.dateNow, staffId: 0, deptId: 0, status: 0, pay: 0, payTransport: 0, payService: 0, payOnbehalf: 0 };
                this.getByAccount();
                this.title = "Thêm mới phải thu khách hàng";
            }
        },

        close(){
            this.showDialog = false;
        },

        addRowFee(type){
            let obj = { 
                id: this.uuidv4(this.entity.details.length + 1),
                objectId: null,
                orderId: this.$route.params.id,
                orderDetailId: null,
                orderDetail: { serial: '' },
                typeOfTransport: 1,
                goodsTypeId: 1,
                feeId: 0,
                fee: { feeCode: '', feeName: '' },
                unit: { unitCode: '', unitName: '' },
                unitId: 0,
                type: type,
                quantity: 0,
                vat: 0,
                vatAmount: 0,
                price: 0,
                amount: 0,
                invoiceNumber: '',
                invoiceDate: '',
                taxCode: '',
                invoiceSerial: '',
                invoiceType: '',
                revenueType: 1,
                note: ''
            };
            this.entity.details.push(obj);
        },

        addRow(){
            let obj = { 
                id: this.uuidv4(this.entity.details.length + 1),
                objectId: null,
                orderId: this.$route.params.id,
                orderDetailId: null,
                orderDetail: { serial: '' },
                typeOfTransport: 1,
                goodsTypeId: 1,
                feeId: 0,
                fee: { feeCode: '', feeName: '' },
                unit: { unitCode: '', unitName: '' },
                unitId: 0,
                type: 2,
                quantity: 0,
                vat: 0,
                vatAmount: 0,
                price: 0,
                amount: 0,
                invoiceNumber: '',
                invoiceDate: '',
                taxCode: '',
                invoiceSerial: '',
                invoiceType: '',
                revenueType: 1,
                note: ''
            };
            this.entity.details.push(obj);
        },
        
        delRow(item){
            const index = this.entity.details.findIndex(x => x.id == item.id);
            this.entity.details.splice(index, 1);
        },

        submit(){
            this.entity.orderId = parseFloat(this.$route.params.id);
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.entity.details.length > 0){
                this.entity.details = this.entity.details.filter(item => !(item.feeId == 0));
                this.entity.details.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.entity.details.length <= 0){
                messageBox.showWarning("Vui lòng thêm chi tiết phải thu");
                return;
            }
            else{
                this.entity.details.forEach(function(item, index, self) {
                    self[index].type = parseInt(self[index].type);
                    self[index].amount = parseFloat(self[index].amount);
                    self[index].quantity = parseFloat(self[index].quantity);
                    self[index].price = parseFloat(self[index].price);
                });
            }
            if(this.id > 0){
                this.edit();
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            revenueService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            revenueService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            revenueService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.staffName = response.data.staff.fullName;
                    this.companyName = this.entity.company.companyAlias;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        calSummary(){
            this.entity.pay = 0;
            this.entity.payService = 0;
            this.entity.payOnbehalf = 0;
            this.entity.payTransport = 0;
            for (var i = 0; i < this.entity.details.length; i++) {
                if(parseFloat(this.entity.details[i].type) == 2){
                    this.entity.payOnbehalf = this.entity.payOnbehalf + parseFloat(this.entity.details[i].amount);
                }
                else if(parseFloat(this.entity.details[i].type) == 3){
                    this.entity.payService = this.entity.payService + parseFloat(this.entity.details[i].amount);
                }
                else if(parseFloat(this.entity.details[i].type) == 5){
                    this.entity.payTransport = this.entity.payTransport + parseFloat(this.entity.details[i].amount);
                }
                this.entity.pay = this.entity.pay + parseFloat(this.entity.details[i].amount);
            }
        },

        calSumVat(item){
            const vatAmount = parseFloat(item.vatAmount);
            let amountNotVat = parseFloat(item.price) * parseFloat(item.quantity);
            item.vat = ((vatAmount / amountNotVat) * 100);
            item.amount = amountNotVat + vatAmount;

            this.calSummary();
        },

        calSum(item){
            const selected = this.entity.details.findIndex(x => x.id == item.id);
            let price = parseFloat(this.entity.details[selected].price);
            let vat =  parseFloat(this.entity.details[selected].vat) / 100;
            this.entity.details[selected].amount = Math.round(price +  (price * vat)) * parseFloat(this.entity.details[selected].quantity);
            let amountNotVat = parseFloat(item.price) * parseFloat(item.quantity);
            item.vatAmount = Math.round(amountNotVat * vat);
            this.calSummary();
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.currentUser = response.data;
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getCode(staffId){
            this.setLoading(true);
            revenueService.getCode(staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.revenueCode = response.data.code;
                    this.entity.revenueNumber = response.data.number;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Sales
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.getCode(item.id);
            this.$refs.staffList.close();
        },

        getStaffSelected(item){
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.staffName = item.fullName;
            this.getCode(item.id);
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //Unit
        closeUnit(item){
            const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
            this.entity.details[selected].unit = item;
            this.entity.details[selected].unitId = item.id;
            this.$refs.unitList.close();
            this.selectedId = '';
        },

        openUnit(id){
            this.selectedId = id;
            this.$refs.unitList.open();
        },

        //Fee
        closeFee(item){
            const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
            this.entity.details[selected].fee = item;
            this.entity.details[selected].feeId = item.id;
            this.$refs.feeList.close();
            this.selectedId = '';
            this.index = -2;
        },

        openFee(id){
            this.selectedId = id;
            this.$refs.feeList.open();
        },

        uuidv4(id) {
            let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
            );

            return uuid + "-" + id;
        },
    },
    watch: { 
        staffName: function (val) { 
            if(val == ''){
                this.entity.staffId = 0;
            }
        },
        companyName: function (val) { 
            if(val == false){
                this.companyName = '';
                this.entity.companyId = 0;
            }
        },
    },
    validations: {
        entity: {
            monthYear: { required },
            revenueCode: { required },
            revenueDate: { required },
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            details: {
                $each: {
                    quantity: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    feeId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                }
            }
        }
    }
})
</script>

<style lang="css" scoped>
    .md-content {
        max-width: 1200px;
        width: 1200px;
    }
</style>
