<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-if="tab == 'tab-info' || tab == 'tab-file' || tab == 'tab-distribute'" v-shortkey="['ctrl', 'l']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <md-tabs>
                        <md-tab @click="onTabClick('tab-info')" id="tab-info" class="tab-content" md-label="Thông tin chung">
                            <div class="row">
                                <div class="col l-3 m-3 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers" :class="{'md-invalid': submitted && $v.entity.customerId.$error }">
                                            <label>Khách hàng<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.customerId.isSelected">Vui lòng chọn khách hàng</span>
                                        </md-autocomplete>
                                        <md-button @click="openCustomer()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                    <div class="form-control">
                                        <md-autocomplete v-model="projectName" @md-selected="getProjectSelected" :md-options="projects" @md-changed="getProjects"  @md-opened="getProjects" :class="{'md-invalid': submitted && $v.entity.projectId.$error }">
                                            <label>Dự án<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.projectCode }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.projectId.isSelected">Vui lòng chọn dự án</span>
                                        </md-autocomplete>
                                        <md-button @click="openProject()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                    <div class="form-control">
                                        <md-autocomplete v-model="contractName" @md-selected="getContractSelected" :md-options="contracts" @md-changed="getContracts"  @md-opened="getContracts" :class="{'md-invalid': submitted && $v.entity.contractId.$error }">
                                            <label>Hợp đồng<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.contractName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.contractId.isSelected">Vui lòng chọn hợp đồng</span>
                                        </md-autocomplete>
                                        <md-button @click="openContract()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <div class="row">
                                        <div class="col l-6 m-6 c-12">
                                            <md-field :class="{'md-invalid': submitted && $v.entity.orderCode.$error }">
                                                <label for="orderCode">Mã code<span class="label-require">(*)</span></label>
                                                <md-input disabled name="orderCode" v-model="entity.orderCode"></md-input>
                                                <span class="md-error" v-if="submitted && !$v.entity.orderCode.required">Vui lòng nhập mã đơn hàng thường</span>
                                            </md-field>
                                        </div>
                                        <div class="col l-6 m-6 c-12">
                                            <md-field>
                                                <label for="orderName">Mã lô hàng</label>
                                                <md-input name="orderName" v-model="entity.orderName"></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                    <div class="form-control">
                                        <md-autocomplete v-model="ownerName" @md-selected="getOwnerSelected" :md-options="owners" @md-changed="getOwners"  @md-opened="getOwners" :class="{'md-invalid': submitted && $v.entity.ownerId.$error }">
                                            <label>Chủ hàng<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.ownerId.isSelected">Vui lòng chọn chủ hàng</span>
                                        </md-autocomplete>
                                        <md-button @click="openOwner()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                    <md-field :class="{'md-invalid': submitted && $v.entity.branchId.$error }">
                                        <label for="branchId">Chi nhánh<span class="label-require">(*)</span></label>
                                        <md-select v-model="entity.branchId" name="branchId" id="branchId">
                                            <md-option value="0">Chọn chi nhánh</md-option>
                                            <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="submitted && !$v.entity.branchId.isSelected">Vui lòng chọn chi nhánh</span>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <div class="form-control">
                                        <md-field :class="{'md-invalid': submitted && $v.entity.planId.$error }">
                                            <label>Phương án<span class="label-require">(*)</span></label>
                                            <md-select v-model="entity.planId">
                                                <md-option v-for="item in planList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="submitted && !$v.entity.planId.isSelected">Vui lòng chọn phương án vận chuyển</span>
                                        </md-field>
                                    </div>
                                    <div class="form-control">
                                        <md-field :class="{'md-invalid': submitted && $v.entity.typeOfTransport.$error }">
                                            <label>Loại hình<span class="label-require">(*)</span></label>
                                            <md-select v-model="entity.typeOfTransport">
                                                <md-option v-for="item in typeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="submitted && !$v.entity.typeOfTransport.isSelected">Vui lòng chọn loại hình vận chuyển</span>
                                        </md-field>
                                    </div>
                                    <div class="form-control">
                                        <md-field :class="{'md-invalid': submitted && $v.entity.requestId.$error }">
                                            <label>Loại yêu cầu<span class="label-require">(*)</span></label>
                                            <md-select v-model="entity.requestId">
                                                <md-option v-for="item in requireList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="submitted && !$v.entity.requestId.isSelected">Vui lòng chọn yêu cầu vận chuyển</span>
                                        </md-field>
                                    </div>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <div class="row">
                                        <div class="col l-6 m-6 c-12">
                                            <md-datepicker v-model="entity.orderDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.orderDate.$error }">
                                                <label>Ngày lập<span class="label-require">(*)</span></label>
                                                <span class="md-error" v-if="submitted && !$v.entity.orderDate.required">Vui lòng chọn ngày tạo</span>
                                            </md-datepicker>
                                        </div>
                                        <div class="col l-6 m-6 c-12">
                                            <md-datepicker v-model="entity.deliveryDate" md-immediately :md-model-type="String">
                                                <label>Đóng/giao hàng</label>
                                            </md-datepicker>
                                        </div>
                                    </div>
                                    <div class="row" style="padding: 10px 0;">
                                        <div class="col l-5 m-5 c-12">
                                            <div class="checkbox-group">
                                                <div class="form-control">
                                                    <md-checkbox v-model="entity.isTransport">Vận tải?</md-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col l-7 m-7 c-12">
                                            <div class="checkbox-group">
                                                <div class="form-control">
                                                    <md-checkbox v-model="entity.isHandle">Thủ tục hải quan?</md-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-control">
                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                            <label>Nhân viên<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên</span>
                                        </md-autocomplete>
                                        <md-button @click="openStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>  
                            </div>
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="tool-bar">
                                        <md-button @click="addOrderItem()" class="md-primary"><md-icon>add_card</md-icon>Thêm hàng hóa<md-tooltip>Thêm hàng vận chuyển</md-tooltip></md-button>
                                        <md-button @click="delAllItem()" class="md-primary" style="color: red;"><md-icon style="color: red;">delete_outline</md-icon>Xóa tất cả<md-tooltip>Xóa hàng vận chuyển</md-tooltip></md-button>
                                        <md-button @click="showDialogTakeCont = true" class="md-primary"><md-icon>minor_crash</md-icon> Cập nhật ngày lấy cont<md-tooltip>Cập nhật ngày lấy cont ra khỏi cảng</md-tooltip></md-button>
                                    </div>
                                    <div id="table-scroll" class="table-scroll" style="height: calc(100vh - 326px); overflow-x: auto; width: 100%;">
                                        <table class="data-table" style="width: 2500px !important;"> 
                                            <thead> 
                                                <tr> 
                                                    <th style="width:60px;">#</th> 
                                                    <th style="width:50px; text-align: center; background-color: #eeeeee; z-index: 9999;">
                                                        <md-checkbox v-model="selectAllDetail"></md-checkbox>
                                                    </th>
                                                    <th style="width:80px;">Hành động</th>
                                                    <th style="width:200px;">Tên hàng</th>
                                                    <th style="width:150px;">Ngày giao/nhận</th>
                                                    <th style="width:150px;">Ngày lấy cont</th>
                                                    <th style="width:100px;">Đơn vị</th> 
                                                    <th style="width:150px;">Số con't</th> 
                                                    <th style="width:100px;">Số seal</th>
                                                    <th style="width:150px;">Loại hình</th> 
                                                    <th style="width:150px;">Loại hàng</th>
                                                    <th style="width:350px;">Tuyến đường</th> 
                                                    <th style="width:200px;">Id</th> 
                                                </tr>
                                            </thead> 
                                            <tbody v-if="loadding == true" style="height: 100px;"> 
                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                            </tbody>
                                            <tbody v-if="loadding == false && entity.details.length > 0"> 
                                                <tr v-for="(item, index) in entity.details" :key="'item-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                    <td class="center">{{index + 1}}</td>
                                                    <td class="center"><md-checkbox v-model="checkedDetailList" :value="item.id"></md-checkbox></td>
                                                    <td class="action" style="text-align: center">
                                                        <md-button @click="editOrderItem(item)" class="md-fab md-mini md-second">
                                                            <md-icon>edit</md-icon>
                                                            <md-tooltip>Sửa</md-tooltip>
                                                        </md-button>
                                                        <md-button @click="openUpload(item)" class="md-fab md-mini md-upload">
                                                            <i class="uil uil-upload"></i>
                                                            <md-tooltip>Upload file</md-tooltip>
                                                        </md-button>
                                                        <md-button v-on:click="delOrderItem(item.id)" class="md-fab md-mini md-plain">
                                                            <md-icon>clear</md-icon>
                                                            <md-tooltip>Xóa</md-tooltip>
                                                        </md-button>
                                                    </td> 
                                                    <td class="center">
                                                        {{item.goods.goodsName}}
                                                    </td>
                                                    <td class="center">
                                                        {{item.dateOfReceipt}}
                                                    </td> 
                                                    <td class="center">
                                                        {{item.dateTakeCont}}
                                                    </td> 
                                                    <td class="center">
                                                        {{item.unit.unitName}}
                                                    </td>
                                                    <td class="center">
                                                        {{item.serial}}
                                                    </td>
                                                    <td class="center">
                                                        {{item.seal}}
                                                    </td>
                                                    <td class="center">
                                                        {{ getTypeOfTransport(item.typeOfTransport) }}
                                                    </td> 
                                                    <td class="center">
                                                        {{ getGoodsType(item.goodsTypeId) }}
                                                    </td>
                                                    <td class="left" style="padding: 0 5px; text-wrap: wrap;">
                                                        {{  getLocation(item)  }}
                                                    </td> 
                                                    <td class="center">
                                                        {{item.code}}
                                                    </td> 
                                                </tr> 
                                            </tbody> 
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-booking')" id="tab-booking" class="tab-content" md-label="Bill/booking">
                            <booking />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-customs')" id="tab-customs" class="tab-content" md-label="Khai báo hải quan">
                            <customs />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-estimate')" id="tab-estimate" class="tab-content" md-label="Dự toán">
                            <estimate :order="entity" />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-distribute')" id="tab-distribute" class="tab-content" md-label="Phân bổ-cước vận chuyển">
                            <div>
                                <div class="row">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="tool-bar">
                                            <md-button @click="showDialog = true" class="md-primary"><md-icon>minor_crash</md-icon>Nhập phân bổ<md-tooltip>Phân bổ hàng</md-tooltip></md-button>
                                            <md-button @click="showDialogTransport = true" class="md-primary"><md-icon>minor_crash</md-icon>Nhập cước thu KH<md-tooltip>Nhập cước thu khách hàng</md-tooltip></md-button>
                                            <md-button @click="loadSetingFeeTransport()" class="md-primary"><md-icon>paid</md-icon> Tải cước phí vận chuyển<md-tooltip>Tải thiết lập cước phí vận chuyển</md-tooltip></md-button>
                                            <md-button @click="loadSetingFeeDistribute()" class="md-primary"><md-icon>paid</md-icon> Tải cước phí phân bổ<md-tooltip>Tải thiết lập cước phí phân bổ</md-tooltip></md-button>
                                            <md-button @click="openAllSubExplain()" class="md-primary"><md-icon>paid</md-icon>Giải chi vendor phụ<md-tooltip>Giải chi cho tất cả vendor</md-tooltip></md-button>
                                            <md-button @click="confirmDeleteAllSubVendor()" class="md-primary" style="color: red;"><md-icon style="color: red;">delete_outline</md-icon>Xóa tất cả<md-tooltip>Xóa giải chi</md-tooltip></md-button>
                                        </div>
                                        <div class="table-scroll-2" style="height: calc(100vh - 308px); overflow-x: auto; width: 100%;">
                                            <table class="data-table-2 distribute" style="width: 2250px; margin-bottom: 0;"> 
                                                <thead> 
                                                    <tr> 
                                                        <th rowspan="2" style="width:60px; background-color: #eee; z-index: 9999;">#</th> 
                                                        <th rowspan="2" style="width:60px; vertical-align: middle; background-color: #eee; z-index: 9999;">
                                                            <md-checkbox v-model="selectAll"></md-checkbox>
                                                        </th> 
                                                        <th colspan="3" style="width:100px;background-color: #eee; z-index: 9999;">Vendor phụ</th>
                                                        <th colspan="2" style="width:100px;background-color: #eee; z-index: 9999;">Xe ngoài</th>
                                                        
                                                        <th colspan="4" style="width:500px; background-color: #FFD18E ;z-index: 9999;">Phân bổ vận tải</th>
                                                        <th colspan="3" style="width:450px; background-color: #95D2B3;">Cước thu KH</th>
                                                        <th rowspan="2" style="width:200px;background-color: #eee;">Tên hàng</th>
                                                        <th rowspan="2" style="width:100px;background-color: #eee;">Đơn vị</th> 
                                                        <th rowspan="2" style="width:150px;background-color: #eee;">Số con't</th> 
                                                        <th rowspan="2" style="width:350px;background-color: #eee;">Tuyến đường</th> 
                                                        <th rowspan="2" style="width:150px;background-color: #eee;">Loại hình</th> 
                                                        <th rowspan="2" style="width:150px;background-color: #eee;">Loại hàng</th>
                                                        <th rowspan="2" style="width:100px;background-color: #eee;">Số seal</th>
                                                    </tr>
                                                    <tr> 
                                                        <th style="width:120px;background-color: #eee; z-index: 9999;">Giải chi</th> 
                                                        <th style="width:120px;background-color: #eee; z-index: 9999;">Trạng thái</th>
                                                        <th style="width:120px;background-color: #eee; z-index: 9999;">Vendor</th> 

                                                        <th style="width:120px;background-color: #eee; z-index: 9999;">Giải chi</th> 
                                                        <th style="width:120px;background-color: #eee; z-index: 9999;">Trạng thái</th>

                                                        <th style="width:150px; background-color: #FFD18E;z-index: 9999;">Phân bổ vận tải</th>
                                                        <th class="center" style="width:160px; background-color: #FFD18E; z-index: 9999;">Cước phí</th>
                                                        <th class="center" style="width:150px; background-color: #FFD18E; z-index: 9999;">VAT</th>
                                                        <th class="right" style="width:150px; background-color: #FFD18E; z-index: 9999;">Thành tiền</th>
                                                        <th class="center" style="width:150px; background-color: #95D2B3;">Cước phí</th>
                                                        <th class="center" style="width:150px; background-color: #95D2B3;">VAT</th>
                                                        <th class="right" style="width:150px; background-color: #95D2B3;">Thành tiền</th>
                                                    </tr>
                                                </thead> 
                                                <tbody v-if="loadding == true" style="height: 100px;"> 
                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                </tbody>
                                                <tbody v-if="loadding == false && entity.details.length > 0"> 
                                                    <tr v-for="(item, index) in entity.details" :key="'item-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                        <td class="center">{{index + 1}}</td>
                                                        <td class="center">
                                                            <md-checkbox v-model="checkedList" :value="item.id"></md-checkbox>
                                                        </td>
                                                        <td class="action" style="width: 120px;text-align: center">
                                                            <md-button @click="openRefundSubVendor(item)" class="md-fab md-mini md-view">
                                                                <i class="uil uil-usd-circle"></i>
                                                                <md-tooltip>Giải chi xà lan</md-tooltip>
                                                            </md-button>
                                                        </td>
                                                        <td class="center" style="width: 120px;">
                                                            <md-chip v-if="item.subDistributeId > 0" class="md-primary">Đã giải chi</md-chip>
                                                            <md-chip v-else>Chưa giải chi</md-chip>
                                                        </td>
                                                        <td class="center" style="width: 120px;">
                                                            <span v-if="item.subVendor">{{ item.subVendor.companyAlias }}</span>
                                                        </td>

                                                        <td class="action" style="width: 120px;text-align: center">
                                                            <md-button v-if="item.distributeId !== 1" @click="openRefund(item)" class="md-fab md-mini md-view">
                                                                <i class="uil uil-usd-circle"></i>
                                                                <md-tooltip>Giải chi vendor</md-tooltip>
                                                            </md-button>
                                                            <md-button v-else disabled class="md-fab md-mini md-view">
                                                                <i class="uil uil-usd-circle"></i>
                                                                <md-tooltip>Giải chi vendor</md-tooltip>
                                                            </md-button>
                                                        </td>
                                                        <td class="center" style="width: 120px;">
                                                            <md-chip v-if="item.refundAmount > 0" class="md-primary">Đã giải chi</md-chip>
                                                            <md-chip v-else>Chưa giải chi</md-chip>
                                                        </td>
                                                        <td class="center" style="width: 120px;">
                                                            <div class="grid-control">
                                                                <input v-model="item.distribute.companyAlias" class="form-control" type="text">
                                                                <md-button @click="openDistribute(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm công ty</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                        </td> 
                                                        <td class="center" style="width: 120px;">
                                                            <div class="group-control">
                                                                <number @change="calSum(item)" v-model="item.distributePrice" v-bind="currencyF2" class="form-control currency"></number> 
                                                            </div>
                                                        </td>
                                                        <td class="center" style="width: 120px;">
                                                            <div class="group-control">
                                                                <number @change="calSum(item)" v-model="item.distributeVat" v-bind="vatF" class="form-control currency"></number> 
                                                            </div>
                                                        </td>
                                                        <td class="right" style="width: 120px;">
                                                            <span>{{ formatNumber(item.distributeAmount) }}</span>
                                                        </td>
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <number @change="calSum(item)" v-model="item.transportPrice" v-bind="currencyF2" class="form-control currency"></number> 
                                                            </div>
                                                        </td>
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <number @change="calSum(item)" v-model="item.transportVat" v-bind="vatF" class="form-control currency"></number> 
                                                            </div>
                                                        </td>
                                                        <td class="right">
                                                            <span>{{ formatNumber(item.transportAmount) }}</span>
                                                        </td>
                                                        <td class="center">
                                                            {{item.goods.goodsName}}
                                                        </td>
                                                        <td class="center">
                                                            {{item.unit.unitName}}
                                                        </td>
                                                        <td class="center">
                                                            {{item.serial}}
                                                        </td>
                                                        <td class="left" style="padding: 0 5px;">
                                                            {{  getLocation(item)  }}
                                                        </td> 
                                                        <td class="center">
                                                            {{ getTypeOfTransport(item.typeOfTransport) }}
                                                        </td> 
                                                        <td class="center">
                                                            {{ getGoodsType(item.goodsTypeId) }}
                                                        </td>
                                                        <td class="center">
                                                            {{item.seal}}
                                                        </td>
                                                    </tr> 
                                                </tbody> 
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-refund')" id="tab-refund" class="tab-content" md-label="Phải trả vendor">
                            <refund :order="entity" />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-revenue')" id="tab-revenue" class="tab-content" md-label="Thu KH">
                            <revenue :order="entity" />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-profit')" id="tab-profit" class="tab-content" md-label="Chi phí - doanh thu">
                            <profitForm :order="entity" />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-file')" id="tab-file" class="tab-content" md-label="Upload file">
                            <upload ref="upload" :updateFileInParent="updateFile"/>
                        </md-tab>
                    </md-tabs>
                </div>
            </div>
        </div>
        <contractList ref="contractList" @close="closeContract"/>
        <projectList ref="projectList" @close="closeProject"/>
        <itemForm ref="itemForm" @close="closeOrderItem" />
        <editForm ref="editForm" @close="closeEditItem" />
        <staffList ref="staffList" @close="closeStaff"/>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <ownerList ref="ownerList" title="chủ hàng" :type="ownerType" @close="closeOwner"/>
        <distributeList ref="distributeList" title="công ty vận tải" @close="closeDistribute"/>
        <assignList ref="assignList" title="công ty vận tải" @close="closeAssign"/>
        <crudRefundDetailForm ref="crudRefundDetailForm" @refresh="getById()"/>
        <crudRefundSubVendorDetail ref="crudRefundSubVendorDetail" @refresh="getById()"/>
        <crudRefundAllSubVendorDetailForm ref="crudRefundAllSubVendorDetailForm" @refresh="getById()"/>
        <uploadItem ref="uploadItem" @close="getById" />

        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9998;">
            <md-dialog-title>Phân bổ hàng hóa</md-dialog-title>
            <md-content class="md-scrollbar">
                <div style="width: 450px;" class="row">
                    <div class="col l-12 m-12 c-12">
                        <div class="form-control">
                            <md-autocomplete v-model="assignName" @md-selected="getAssignSelected" :md-options="assigns" @md-changed="getAssigns"  @md-opened="getAssigns" :class="{'md-invalid': submittedAssign && assignId == 0 }">
                                <label>Đơn vị vận chuyển</label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                    <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                    "{{ term }}" Không tìm thấy!
                                </template>
                                <span class="md-error" v-if="submittedAssign && assignId == 0">Vui lòng chọn đơn vị vận chuyển</span>
                            </md-autocomplete>
                            <md-button @click="openAssign()" class="md-icon-button">
                                <md-icon>manage_search</md-icon>
                            </md-button>
                        </div>
                        <md-field class="number-control">
                            <div>Cước phí:</div>
                            <number @change="calDistribute()" name="distributePrice" v-model="distributePrice" v-bind="currencyF2"></number> 
                        </md-field>
                        <md-field class="number-control">
                            <div>VAT(%):</div>
                            <number @change="calDistribute()" name="distributeVat" v-model="distributeVat" v-bind="vatF"></number> 
                        </md-field>
                        <md-field class="number-control">
                            <div>Thành tiền:</div>
                            <number disabled name="distributeAmount" v-model="distributeAmount" v-bind="currencyF"></number> 
                        </md-field>
                    </div>
                </div>
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submitDistribute()">Chọn</md-button>
                <md-button class="md-primary" @click="close()">Đóng</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-active.sync="showDialogTransport" :md-click-outside-to-close="false" style="z-index: 9998;">
            <md-dialog-title>Cước thu khách hàng</md-dialog-title>
            <md-content class="md-scrollbar">
                <div style="width: 450px;" class="row">
                    <div class="col l-12 m-12 c-12">
                        <md-field class="number-control">
                            <div>Cước phí:</div>
                            <number @change="calTransport()" name="transportPrice" v-model="transportPrice" v-bind="currencyF2"></number> 
                        </md-field>
                        <md-field class="number-control">
                            <div>VAT(%):</div>
                            <number @change="calTransport()" name="transportVat" v-model="transportVat" v-bind="vatF"></number> 
                        </md-field>
                        <md-field class="number-control">
                            <div>Thành tiền:</div>
                            <number disabled name="transportAmount" v-model="transportAmount" v-bind="currencyF"></number> 
                        </md-field>
                    </div>
                </div>
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submitTransport()">Chọn</md-button>
                <md-button class="md-primary" @click="showDialogTransport = false">Đóng</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-active.sync="showDialogTakeCont" :md-click-outside-to-close="false" style="z-index: 9998;">
            <md-dialog-title>Cập nhật ngày lấy cont ra khỏi cảng</md-dialog-title>
            <md-content class="md-scrollbar">
                <div style="width: 450px;" class="row">
                    <div class="col l-12 m-12 c-12">
                        <div class="form-control">
                            <md-datepicker v-model="dateTakeCont" md-immediately :md-model-type="String" :class="{'md-invalid': submittedTakeCont && dateTakeCont == '' }">
                                <label>Ngày lấy cont<span class="label-require">(*)</span></label>
                                <span class="md-error" v-if="submittedTakeCont && dateTakeCont == ''">Vui lòng chọn ngày lấy cont ra khỏi cảng</span>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submitDateTakeCont()">Lưu</md-button>
                <md-button class="md-primary" @click="showDialogTakeCont = false">Đóng</md-button>
            </md-dialog-actions>
        </md-dialog>
   </div>
</template>

<script>
    import settingDebtService from '../../../api/settingDebtService';
    import branchService from '../../../api/branchService';
    import staffService from '../../../api/staffService';
    import orderService from '../../../api/orderService';
    import contractService from '../../../api/contractService';
    import companyService from '../../../api/companyService';
    import projectService from '../../../api/projectService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import contractList from '../../../components/popup/_ContractList.vue';
    import projectList from '../../../components/popup/_ProjectList.vue';
    import staffList from '../../../components/popup/_StaffList.vue';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import ownerList from '../../../components/popup/_CompanyList.vue';
    import distributeList from '../../../components/popup/_TransportList.vue';
    import assignList from '../../../components/popup/_TransportList.vue';
    import itemForm from './AddItem.vue';
    import editForm from './EditItem.vue';
    import booking from './Booking.vue';
    import customs from './Customs.vue';
    import estimate from './Estimate.vue';
    import revenue from './Revenue.vue';
    import refund from './Refund.vue';
    import crudRefundDetailForm from './CrudRefundDetail.vue';
    import crudRefundSubVendorDetail from './CrudRefundSubVendorDetail.vue';
    import profitForm from './Profit.vue';
    import crudRefundAllSubVendorDetailForm from './CrudRefundAllSubVendorDetail.vue';
    import upload from '../../../components/Upload.vue';
    import uploadItem from './UploadItem.vue';
    import refundService from '../../../api/refundService';

    export default ({
        components: {
            upload,
            uploadItem,
            refund,
            itemForm,
            editForm,
            contractList,
            projectList,
            staffList,
            customerList,
            ownerList,
            booking,
            customs,
            distributeList,
            assignList,
            estimate,
            revenue,
            crudRefundDetailForm,
            crudRefundSubVendorDetail,
            profitForm,
            crudRefundAllSubVendorDetailForm
        },
        metaInfo: {
            title: 'Thêm/Cập nhật đơn hàng thường'
        },
        data() {
            return {
                title: '',
                id: 0,
                tab: 'tab-info',
                selectedId: 0,
                submittedAssign: false,
                showDialogTakeCont: false,
                assignId: 0,
                assign: null,
                submitted: false,
                loadding: false,
                currencyF2: common.currencyF2,
                typeList: common.typeOfTransport,
                planList: common.planOfTransport,
                requireList: common.requireOfTransport,
                customerType: common.companyType.customer,
                ownerType: common.companyType.customer,
                entity: { id: 0, files: [], branchId: 0, ownerId: 0, isPort: 0, orderType: 1, isHandle: true, isTransport: true, typeOfTransport: 1, staffId: 0, deptId: 0, planId: 1, requestId: 1, details: [], orderCode: '', contractId: 0, customerId: 0, projectId: 0, orderDate: common.dateNow, deliveryDate: null, note: '', status: 0 },
                branchs: [],
                contractName: '',
                contracts: [],
                projectName: '',
                projects: [],
                staffName: '',
                staffs: [],
                customerName: '',
                customers: [],
                ownerName: '',
                owners: [],
                selectAll: false,
                checkedList: [],
                showDialog: false,
                assigns: [],
                assignName: '',
                currencyF: common.currencyF,
                vatF: common.vatF,
                currencyUserId: 0,
                selectAllDetail: false,
                checkedDetailList: [],
                submittedTakeCont: false,
                dateTakeCont: '',
                distributeAmount: 0,
                distributePrice: 0,
                distributeVat: 0,
                transportAmount: 0,
                transportPrice: 0,
                transportVat: 0,
                submittedTransport: false,
                showDialogTransport: false
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật đơn hàng thường';
               this.getById();
            }
            else{
               this.title = 'Thêm mới đơn hàng thường';
               this.getCode();
            }
            this.getByAccount();
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calTransport(){
                this.transportAmount = parseFloat(this.transportPrice) + (parseFloat(this.transportPrice) * (parseFloat(this.transportVat) / 100));
            },

            calDistribute(){
                this.distributeAmount = parseFloat(this.distributePrice) + (parseFloat(this.distributePrice) * (parseFloat(this.distributeVat) / 100));
            },

            confirmDeleteAllSubVendor(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                else{
                    this.$modal.show('dialog', {
                        title: 'Thông báo',
                        text: 'Bạn có chắc muốn xóa không?',
                        buttons: [
                                {
                                    title: 'Hủy',
                                    handler: () => {
                                        this.$modal.hide('dialog')
                                    }
                                },
                                {
                                    title: 'Xóa',
                                    handler: () => {
                                        this.delAllSubVendor()
                                    }
                                }
                        ]
                    })
                }
            },

            delAllSubVendor(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                refundService.deleteAllSubVendor(this.checkedList.toString()).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.getById();
                        this.checkedList = [];
                        this.$modal.hide('dialog')
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            openUpload(item) {
                this.$refs.uploadItem.open(item);
            },

            updateFile(files){
                this.entity.files = files;
            },

            openAllSubExplain(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                this.$refs.crudRefundAllSubVendorDetailForm.open(this.checkedList);
            },

            delAllItem(){
                this.entity.details = [];
            },

            submitDateTakeCont(){
                this.submittedTakeCont = true;
                if (this.dateTakeCont == '') {
                    return;
                }
                if(this.checkedDetailList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                for(let i = 0; i < this.checkedDetailList.length; i++){
                    let selected = this.entity.details.findIndex(x => x.id == this.checkedDetailList[i]);
                    this.entity.details[selected].dateTakeCont = this.dateTakeCont;
                }
                orderService.updateDateTakeCont(this.entity.details).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();

                this.showDialogTakeCont = false;
            },

            openRefundSubVendor(obj){
                this.$refs.crudRefundSubVendorDetail.open(obj);
            },

            openRefund(obj){
                if(obj.distributeId == null || obj.distributeId == 0){
                    messageBox.showWarning("Vui lòng phân bổ đơn vị ngoài trước khi thực hiện giải chi");
                }
                else{
                    this.$refs.crudRefundDetailForm.open(obj);
                }
            },

            loadSetingFeeDistribute(){
                this.setLoading(true);
                settingDebtService.getSettingForDistribute(this.id).then((response) => {
                    if(response.statusCode == 200){
                        response.data.map(item => {
                            const selected = this.entity.details.findIndex(x => x.id == item.id);
                            if(selected > -1){
                                this.entity.details[selected].distributeAmount = item.distributeAmount;
                                this.entity.details[selected].distributeVat = item.distributeVat;
                                this.entity.details[selected].distributePrice = item.distributePrice;
                            }
                        });
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            loadSetingFeeTransport(){
                this.setLoading(true);
                settingDebtService.getSettingByOrder(this.id).then((response) => {
                    if(response.statusCode == 200){
                        response.data.map(item => {
                            const selected = this.entity.details.findIndex(x => x.id == item.id);
                            if(selected > -1){
                                this.entity.details[selected].transportAmount = item.transportAmount;
                                this.entity.details[selected].transportVat = item.transportVat;
                                this.entity.details[selected].transportPrice = item.transportPrice;
                            }
                        });
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            calSum(item){
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                let distributePrice = parseFloat(this.entity.details[selected].distributePrice);
                let distributeVat = distributePrice * (parseFloat(this.entity.details[selected].distributeVat) / 100);
                this.entity.details[selected].distributeAmount = Math.round(distributePrice + distributeVat);

                let transportPrice = parseFloat(this.entity.details[selected].transportPrice);
                let transportVat = transportPrice * (parseFloat(this.entity.details[selected].transportVat) / 100);
                this.entity.details[selected].transportAmount = Math.round(transportPrice + transportVat);
            },

            getLocation(item) {
                let location = [];
                if(item.placeOfReceiptCont !== null && item.placeOfReceiptCont !== undefined){
                    location.push(item.placeOfReceiptCont.locationName);
                }
                if(item.placeOfReceiptGoods !== null && item.placeOfReceiptGoods !== undefined){
                    location.push(item.placeOfReceiptGoods.locationName);
                }
                if(item.transitDelivery !== null && item.transitDelivery !== undefined){
                    location.push(item.transitDelivery.locationName);
                }
                if(item.transitReceipt !== null && item.transitReceipt !== undefined){
                    location.push(item.transitReceipt.locationName);
                }
                if(item.placeOfDeliveryGoods !== null && item.placeOfDeliveryGoods !== undefined){
                    location.push(item.placeOfDeliveryGoods.locationName);
                }
                if(item.placeOfDeliveryCont !== null && item.placeOfDeliveryCont !== undefined){
                    location.push(item.placeOfDeliveryCont.locationName);
                }

                if(location.length > 0){
                    return location.join([' - ']);
                }
                else{
                    return "";
                }
            },

            submitTransport(){
                this.submittedTransport = true;
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn hàng hóa");
                    return;
                }
                for(let i = 0; i < this.checkedList.length; i++){
                    let selected = this.entity.details.findIndex(x => x.id == this.checkedList[i]);
                    this.entity.details[selected].transportPrice = this.transportPrice;
                    this.entity.details[selected].transportVat = this.transportVat;
                    this.entity.details[selected].transportAmount = this.transportAmount;
                }
                this.showDialogTransport = false;
            },

            submitDistribute(){
                this.submittedAssign = true;
                if (this.assignId == 0) {
                    return;
                }
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn hàng hóa");
                    return;
                }

                for(let i = 0; i < this.checkedList.length; i++){
                    let selected = this.entity.details.findIndex(x => x.id == this.checkedList[i]);
                    this.entity.details[selected].distribute = this.assign;
                    this.entity.details[selected].distributeId = this.assignId;
                    this.entity.details[selected].distributePrice = this.distributePrice;
                    this.entity.details[selected].distributeVat = this.distributeVat;
                    this.entity.details[selected].distributeAmount = this.distributeAmount;
                    this.entity.details[selected].submitDistributeId = this.currencyUserId;
                    this.entity.details[selected].isPpl = this.assignId == 1;
                    this.entity.details[selected].distributeDate = new Date();
                }
                this.showDialog = false;
            },

            //Assign
            closeAssign(item){
                this.assignName = item.companyAlias;
                this.assign = item;
                this.assignId = item.id;
                this.$refs.assignList.close();
            },

            getAssignSelected(val){
                this.assignName = val.companyAlias;
                this.assignId = val.id;
                this.assign = val;
            },

            openAssign(){
                this.$refs.assignList.open();
            },

            getAssigns(searchTerm){
                let search = { pageIndex: 1, pageSize: 30,  code: searchTerm };
                companyService.getTransport(search).then((response) => {
                    if(response.statusCode == 200){
                        this.assigns = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            close(){
                this.showDialog = false;
            },

            //Distribute
            closeDistribute(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].distribute = item;
                this.entity.details[selected].distributeId = item.id;
                this.entity.details[selected].submitDistributeId = this.currencyUserId;
                this.entity.details[selected].isPpl = item.id == 1;
                this.entity.details[selected].distributeDate = new Date();
                this.selectedId = '';
                this.$refs.distributeList.close();
            },

            openDistribute(id){
                this.selectedId = id;
                this.$refs.distributeList.open();
            },

            onTabClick(tabIndex){
                this.tab = tabIndex;
            },

            editOrderItem(item){
                this.$refs.editForm.open(item, this.entity.ownerId);
            },

            closeEditItem(item){
                console.log(item)
            },

            delOrderItem(item){
                const index = this.entity.details.findIndex(x => x.id == item.id);
                this.entity.details.splice(index, 1);
            },

            addOrderItem(){
                this.$refs.itemForm.open(null, this.entity.ownerId, this.entity.typeOfTransport);
            },

            closeOrderItem(list){
                for(let i = 0; i < list.length; i ++){
                    list[i].code = '';
                    list[i].distributePrice = 0;
                    list[i].distributeVat = 0;
                    list[i].distributeAmount = 0;
                    list[i].transportPrice = 0;
                    list[i].transportVat = 0;
                    list[i].transportAmount = 0;
                    list[i].distribute = { companyAlias: '', companyCode: '' }
                    this.entity.details.push(list[i]);
                }
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.details !== undefined && this.entity.details.length > 0){
                    this.entity.details = this.entity.details.filter(item => !(item.goodsId == 0));
                    this.entity.details.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.details === undefined || this.entity.details.length === 0){
                    messageBox.showWarning("Vui lòng thêm hàng hóa");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            //Owner
            closeOwner(item){
                this.ownerName = item.companyAlias;
                this.entity.ownerId = item.id;
                this.$refs.ownerList.close();
            },

            getOwnerSelected(val){
                this.entity.ownerId = val.id;
                this.ownerName = val.companyAlias;
            },

            openOwner(){
                this.$refs.ownerList.open();
            },

            getOwners(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.owners = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.entity.customerId = item.id;
                this.$refs.customerList.close();
                this.entity.contractId = 0;
                this.contractName = '';
                this.getProjectByCustomer(this.entity.customerId);
                this.getContractByCustomer(this.entity.customerId);
            },

            getCustomerSelected(val){
                this.entity.customerId = val.id;
                this.customerName = val.companyAlias;
                this.entity.contractId = 0;
                this.contractName = '';
                this.getProjectByCustomer(this.entity.customerId);
                this.getContractByCustomer(this.entity.customerId);
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Project
            getProjectByCustomer(customerId){
                let search = { pageIndex: 1, pageSize: 30, customerId: customerId, orderType: 1, code: '' };
                projectService.getProjects(search).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data.items.length > 0){
                            this.entity.projectId = response.data.items[0].id;
                            this.projectName = response.data.items[0].projectName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            closeProject(item){
                this.projectName = item.projectCode;
                this.entity.projectId = item.id;
                this.$refs.projectList.close();
            },

            getProjectSelected(val){
                this.entity.projectId = val.id;
                this.projectName = val.projectCode;
            },

            openProject(){
                this.$refs.projectList.open(this.entity.customerId, 1);
            },

            getProjects(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, customerId: this.entity.customerId, orderType: 1, code: searchTerm };
                projectService.getProjects(search).then((response) => {
                    if(response.statusCode == 200){
                        this.projects = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Contract
            getContractByCustomer(customerId){
                let search = { pageIndex: 1, pageSize: 30, customerId: customerId, code: '' };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data.items.length > 0){
                            this.entity.contractId = response.data.items[0].id;
                            this.contractName = response.data.items[0].contractName;
                            this.getContractById(this.entity.contractId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getContractById(id){
                contractService.getById(id).then((response) => {
                    if(response.statusCode == 200){
                        let contract = response.data;
                        if(contract.owners.length > 0){
                            this.entity.ownerId = contract.owners[0].id;
                            this.ownerName = contract.owners[0].companyAlias;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            closeContract(item){
                this.contractName = item.contractName;
                this.entity.contractId = item.id;
                this.entity.customerId = item.customerId;
                this.customerName = item.customer.companyAlias;
                this.$refs.contractList.close();
            },

            getContractSelected(val){
                this.entity.contractId = val.id;
                this.contractName = val.contractName;
                this.entity.customerId = val.customerId;
                this.customerName = val.customer.companyAlias;
            },

            openContract(){
                this.$refs.contractList.open(this.entity.customerId);
            },

            getContracts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, customerId: this.entity.customerId, code: searchTerm };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        this.contracts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId,
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId,
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getCode(){
                orderService.getCode().then((response) => {
                    if(response.statusCode == 200){
                        this.entity.orderCode = response.data.code;
                        this.entity.orderNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.setLoading(true);
                orderService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push("/order/crud/" + response.data.id);
                        this.id = response.data.id;
                        this.getById();
                    }
                    else{
                        this.entity.status = 0;
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                orderService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.getById();
                    }
                    else{
                        this.entity.status = 0;
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                orderService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        if(this.entity.files !== null) {
                            this.$refs.upload.loadData(this.entity.files);
                        }
                        this.contractName = response.data.contract.contractName;
                        this.customerName = response.data.customer.companyAlias;
                        this.projectName = response.data.project.projectCode;
                        this.staffName = response.data.staff.fullName;

                        if(this.entity.ownerId > 0){
                            this.ownerName = this.entity.owner.companyAlias;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.currencyUserId = response.data.id;
                            if(this.entity.id == 0){
                                this.entity.staff = response.data;
                                this.entity.staffId = response.data.id;
                                this.entity.deptId = response.data.departmentId;
                                this.entity.branchId = response.data.department.branchId;
                                this.staffName = response.data.fullName;
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/order');
            }
        },
        watch: { 
            selectAllDetail: function (val){
                if(val == true){
                    this.checkedDetailList = []
                    this.entity.details.map((item) => {
                        this.checkedDetailList.push(item.id);
                    })
                }
                else{
                    this.checkedDetailList = []
                }
            },
            selectAll: function (val){
                if(val == true){
                    this.checkedList = []
                    this.entity.details.map((item) => {
                        this.checkedList.push(item.id);
                    })
                }
                else{
                    this.checkedList = []
                }
            },
            customerName: function (val){
                if(val == ''){
                    this.entity.customerId = 0;
                    this.entity.contractId = 0;
                    this.contractName = '';
                    this.entity.projectId = 0;
                    this.entity.projectName = '';
                    this.projectName = '';
                }
            },
            ownerName: function (val) { 
                if(val == ''){
                    this.entity.ownerId = 0;
                }
            },
            contractName: function (val) { 
                if(val == ''){
                    this.entity.contractId = 0;
                    this.entity.ownerId = 0;
                    this.ownerName = '';
                }
            },
            projectName: function (val) { 
                if(val == ''){
                    this.entity.projectId = 0;
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
            assignName: function (val) { 
                if(val == ''){
                    this.assignId = 0;
                }
            },
        },
        validations: {
            entity: {
                orderCode: { required },
                branchId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                contractId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                customerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                ownerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                projectId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                planId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                requestId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                typeOfTransport: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                orderDate: { required }
            }
        }
     })

</script>

<style scoped>
    .distribute thead  tr th {
        z-index: 9998;
    }
    .table-scroll tr th {
        z-index: 9999;
        background-color: #eee !important;
    }
    tr th.sticky-col, th.sticky-col-2, th.sticky-col-3, th.sticky-col-4 {
        z-index: 9999;
    }
    tr td.sticky-col, td.sticky-col-2, td.sticky-col-3, td.sticky-col-4 {
        z-index: 9998;
    }
    .sticky-col {
        position: sticky !important;
        left: 0 !important;
        z-index: 9999;
    }
    .sticky-col-2 {
        position: sticky !important;
        left: 35px !important;
        z-index: 9999;
    }
    .sticky-col-3 {
        position: sticky !important;
        left: 140px !important;
        z-index: 9999;
    }
    .sticky-col-4 {
        position: sticky !important;
        left: 350px !important;
        z-index: 9999;
    }

    ::-webkit-scrollbar{
        height: 12px;
        width: 0;
        background: #FFF;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background: #e0e0e0;
        border-radius: 10px;
    }
    
    .table-scroll {
        position: relative;
        z-index:1;
        margin: auto;
        overflow-y:scroll;
        height: calc(100vh - 220px);
    }
    .table-scroll table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        display: contents;
    }
    .table-wrap {
        position:relative;
    }
    .table-scroll th, .table-scroll td {
        padding: 5px 10px;
        background: #fff;
        vertical-align: middle;
    }
    .table-scroll tr td{
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll tr th {
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll td:last-child, .table-scroll th:last-child {
        border-right: 1px solid #e0e0e0;
    }
    .table-scroll thead tr:last-child th {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody tr:last-child td {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody th {
        text-align:left;
    }
    .table-scroll thead tr:first-child th {
        position: -webkit-sticky;
        position:sticky;
        top: 0;
        height: 30px;
    }
    .table-scroll thead tr:nth-child(2) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 45px;
    }
    /* safari and ios need the tfoot itself to be position:sticky also */
    .table-scroll tfoot, .table-scroll tfoot th, .table-scroll tfoot td {
        position:-webkit-sticky;
        position:sticky;
        bottom:0;
        background:#666;
        color:#fff;
        text-align:center;
    }
    a:focus {
        background:red
    }/* testing links*/

    .table-scroll-2 {
        position: relative;
        z-index:1;
        margin: auto;
        overflow-y:scroll;
        height: calc(100vh - 220px);
    }
    .table-scroll-2 table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        display: inline-table;
    }
    .table-wrap {
        position:relative;
    }
    .table-scroll-2 th, .table-scroll-2 td {
        padding: 5px 10px;
        background: #fff;
        vertical-align: middle;
    }
    .table-scroll-2 tr td{
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll-2 tr th {
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll-2 td:last-child, .table-scroll-2 th:last-child {
        border-right: 1px solid #e0e0e0;
    }
    .table-scroll-2 thead tr:last-child th {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll-2 tbody tr:last-child td {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll-2 tbody th {
        text-align:left;
    }
    .table-scroll-2 thead tr:first-child th {
        position: -webkit-sticky;
        position:sticky;
        top: 0;
        height: 30px;
    }
    .table-scroll-2 thead tr:nth-child(2) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 45px;
    }
    /* safari and ios need the tfoot itself to be position:sticky also */
    .table-scroll-2 tfoot, .table-scroll-2 tfoot th, .table-scroll-2 tfoot td {
        position:-webkit-sticky;
        position:sticky;
        bottom:0;
        background:#666;
        color:#fff;
        text-align:center;
    }
</style>
