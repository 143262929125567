<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9994;">
        <md-content>
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 's']" @shortkey="submit()" @click="submit()" class="md-raised md-primary">Lưu<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                                <md-button @click="confirmDelete()" class="md-raised md-accent">Xóa<md-tooltip>Xóa</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-5 m-5 c-12">
                                    <div class="group-box" style="margin-top:10px; height: 150px;">
                                        <div class="group-title">THÔNG TIN VENDOR</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="info">
                                                        <div class="item">
                                                            <span class="value">
                                                                <div class="form-control">
                                                                    <md-autocomplete v-model="assignName" @md-selected="getAssignSelected" :md-options="assigns" @md-changed="getAssigns"  @md-opened="getAssigns" :class="{'md-invalid': submitted && vendorId == 0 }">
                                                                        <label>Đơn vị vận chuyển</label>
                                                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                            <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                                                        </template>
                                                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                            "{{ term }}" Không tìm thấy!
                                                                        </template>
                                                                        <span class="md-error" v-if="submitted && vendorId == 0">Vui lòng chọn đơn vị vận chuyển</span>
                                                                    </md-autocomplete>
                                                                    <md-button @click="openAssign()" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                    </md-button>
                                                                </div>
                                                            </span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Nhân viên:</span> 
                                                            <span class="value">{{ staff.fullName }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Ngày tạo:</span> 
                                                            <span class="value">{{ dateNow }}</span> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col l-7 m-7 c-12">
                                    <div class="group-box" style="margin-top:10px; height: 150px;">
                                        <div class="group-title">THÔNG TIN CONT/KIỆN HÀNG</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="info">
                                                        <div class="item">
                                                            <span class="label">Số con't/Kiện:</span> 
                                                            <span class="value">{{ entity.serial }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Đơn vị:</span> 
                                                            <span class="value">{{ entity.unit.unitName }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Tuyến đường:</span> 
                                                            <span class="value">
                                                                <span v-for="(item, index) in entity.locations" :key="'location-' +index">
                                                                    {{ item.locationName }}
                                                                </span>
                                                            </span> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-detail" class="tab-content" md-label="Danh sách chi phí">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                        </div>
                                                        <div class="list" style="overflow-x: auto;">
                                                            <table class="data-table-2"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th style="width:50px;">Hành động</th>
                                                                        <th class="left" style="width:20%;">Tên phí</th>
                                                                        <th style="width:15%;">Loại phí</th>
                                                                        <th style="width:10%;">Đơn giá</th>
                                                                        <th style="width:8%;">VAT</th>
                                                                        <th style="width:10%;">Thành tiền</th>
                                                                        <th style="width:10%;">Số HĐ</th>
                                                                        <th style="width:12%;">Ngày HĐ</th>
                                                                        <th style="width:10%;">Ghi chú</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(item, index) in data" :key="'refund-detail-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="grid-action">
                                                                            <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="item.fee.feeName" :class="{'is-error': $v.data.$each[index].feeId.$error}" class="form-control" type="text">
                                                                                <md-button @click="openFee(item.id)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select @md-selected="calSum(item)" v-model="item.type" name="type" id="type">
                                                                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.price" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="true" v-model="item.amount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.invoiceNumber" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <md-datepicker v-model="item.invoiceDate" md-immediately :md-model-type="String"></md-datepicker>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.note" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <feeList ref="feeList" @close="closeFee"/>
                <assignList ref="assignList" title="công ty vận tải" @close="closeAssign"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import feeList from '../../../components/popup/_FeeList.vue';
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import refundService from '../../../api/refundService';
import staffService from '../../../api/staffService';
import assignList from '../../../components/popup/_TransportList.vue';
import companyService from '../../../api/companyService';

export default ({
    components: {
        feeList,
        assignList
    },
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            submitted: false,
            loading: false,
            vatF: common.vatF,
            currencyF: common.currencyF2,
            types: common.typeFee,
            data: [],
            detail: { id: 'id_' + common.getFakeId(), orderId: 0 },
            orderId: 0,
            entity: { unit: { unitName: '' }, distribute: { companyName: '' } },
            staff: { },
            dateNow: common.dateNow,
            assigns: [],
            assignName: '',
            vendorId: 0,
            orderDetailId: 0
        }
    },
    created(){
        this.orderId = parseFloat(this.$route.params.id);
        this.getByAccount();
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        confirmDelete(){
            this.showDialog = false;
            this.$modal.show('dialog', {
            title: 'Thông báo',
            text: 'Bạn có chắc muốn xóa không?',
            buttons: [
                    {
                        title: 'Hủy',
                        handler: () => {
                            this.$modal.hide('dialog'),
                            this.showDialog = true
                        }
                    },
                    {
                        title: 'Xóa',
                        handler: () => {
                            this.del()
                        }
                    }
            ]
            })
        },

        del(){
            this.$modal.hide('dialog');
            this.setLoading(true);
            refundService.deleteBySubVendorId(this.id, this.vendorId).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Xóa thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getFeeOnBehalf(){
            this.setLoading(true);
            this.entity.details = [];
            refundService.getFeeOnbehalf(this.$route.params.id, this.entity.companyId).then((response) => {
                if(response.statusCode == 200){
                    let i = 1;
                    response.data.map((item) => {
                        let obj = { 
                            id: this.uuidv4(i),
                            orderId: item.orderId,
                            orderDetailId: item.orderDetailId,
                            orderDetail: item.orderDetail,
                            feeId: item.feeId,
                            fee: item.fee,
                            type: item.type,
                            amount: item.invoiceAmount,
                            invoiceNumber: item.invoiceNumber,
                            invoiceDate: item.invoiceDate
                        };
                        i++;
                        this.entity.details.push(obj);
                    });
                    this.calSummary();
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getFeeTransport(){
            if(this.entity.companyId == 0){
                messageBox.showInfo("Vui lòng chọn vendor");
            }
            else{
                this.setLoading(true);
                refundService.getFeeDistribute(this.$route.params.id, this.entity.companyId).then((response) => {
                    if(response.statusCode == 200){
                        response.data.map(item => {
                            item.id =  'id_' + common.getFakeId();
                            this.entity.details.unshift(item);
                            this.entity.payTransport = item.amount;
                        })
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            }
        },

        open(obj){
            this.data = [];
            this.id = obj.id;
            this.orderDetailId = obj.id;
            this.vendorId = obj.subDistributeId > 0 ? obj.subDistributeId : 0;
            this.assignName = obj.subVendor.companyAlias;
            this.entity = obj;
            this.submitted = false;
            this.showDialog = true;
            this.title = "Giải chi chi phí của Vendor";
            this.getDetails();
        },

        close(){
            this.showDialog = false;
        },

        addRow(){
            let item = { 
                id: 'id_' + common.getFakeId(), 
                staffId: this.staff.id,
                orderId: this.$route.params.id, 
                orderDetailId: this.entity.id, 
                fee: { feeCode: '', feeName: '' }, 
                feeId: 0, type: 1, price: 0, vat: 0, amount: 0, 
                invoiceDate: '', invoiceNumber: '', 
                createdDate: this.dateNow, 
                note: '' 
            };
            this.data.push(item);
        },
        
        delRow(item){
            const index = this.data.findIndex(x => x.feeId == item.feeId);
            this.data.splice(index, 1);
            this.calSummary();
        },

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.data.length > 0){
                this.data = this.data.filter(item => !(item.feeId == 0));
                this.data.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.data.length <= 0){
                messageBox.showWarning("Vui lòng thêm chi tiết cước, chi phí");
                return;
            }
            if(this.vendorId == 0 || this.vendorId == null){
                messageBox.showWarning("Vui lòng chọn đơn vị vận chuyển");
                return;
            }
            else{
                this.data.map((item) => {
                    item.type = parseInt(item.type);
                    item.staffId = parseInt(this.staff.id);
                    item.createdDate = this.dateNow;
                });
            }
            refundService.updateDetailSubVendor(this.vendorId, this.orderDetailId, this.data).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getDetails(){
            this.setLoading(true);
            refundService.getDetailSubVendor(this.orderDetailId, this.vendorId).then((response) => {
                if(response.statusCode == 200){
                    this.data = response.data;
                    if(this.data.length == 0){
                        let item = { 
                            id: 'id_' + common.getFakeId(), 
                            staffId: this.staff.id,
                            orderId: this.$route.params.id, 
                            orderDetailId: this.entity.id,
                            fee: { feeCode: '', feeName: 'Phí vận chuyển' }, 
                            feeId: 62, 
                            type: 5, 
                            invoiceDate: '', 
                            invoiceNumber: '', 
                            note: '',
                            createdDate: this.dateNow,
                            price: 0,
                            vat: 0,
                            amount: 0
                        };  

                        this.data.push(item);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        calSummary(){
            this.entity.pay = 0;
            this.entity.payService = 0;
            this.entity.payOnbehalf = 0;
            for (var i = 0; i < this.entity.details.length; i++) {
                if(parseFloat(this.entity.details[i].type) == 2){
                    this.entity.payOnbehalf = this.entity.payOnbehalf + parseFloat(this.entity.details[i].amount);
                }
                else if(parseFloat(this.entity.details[i].type) == 3){
                    this.entity.payService = this.entity.payService + parseFloat(this.entity.details[i].amount);
                }
            }
            this.entity.pay = this.entity.payTransport + this.entity.payService + this.entity.payOnbehalf;
        },

        calSum(item){
            const selected = this.data.findIndex(x => x.feeId == item.feeId);
            let price = parseFloat(this.data[selected].price);
            let vat =  parseFloat(this.data[selected].vat) / 100;
            this.data[selected].amount = Math.round(price +  (price * vat));
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.staff = response.data;
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Fee
        closeFee(item){
            const selected = this.data.findIndex(x => x.id == this.selectedId);
            this.data[selected].fee = item;
            this.data[selected].feeId = item.id;
            this.$refs.feeList.close();
            this.selectedId = '';
            this.index = -2;
        },

        openFee(id){
            this.selectedId = id;
            this.$refs.feeList.open();
        },

        //Assign
        closeAssign(item){
            this.assignName = item.companyAlias;
            this.assign = item;
            this.vendorId = item.id;
            this.getDetails();
            this.$refs.assignList.close();
        },

        getAssignSelected(val){
            this.assignName = val.companyAlias;
            this.vendorId = val.id;
            this.assign = val;
            this.getDetails();
        },

        openAssign(){
            this.$refs.assignList.open();
        },

        getAssigns(searchTerm){
            let search = { pageIndex: 1, pageSize: 30,  code: searchTerm };
            companyService.getTransport(search).then((response) => {
                if(response.statusCode == 200){
                    this.assigns = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        uuidv4(id) {
            let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
            );

            return uuid + "-" + id;
        },
    },
    validations: {
        data: {
            $each: {
                feeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                }
            }
        }
    }
})
</script>

<style lang="css" scoped>
    .md-content {
        max-width: 1200px;
        width: 1200px;
    }
    .item {
        padding: 3px 5px;
    }
    .label {
        font-size: 15px;
        font-weight: bold;
        padding-right: 10px;
    }
    .value {
        font-size: 15px;
    }
</style>
