<template>
    <div class="table-content" style="padding: 0">
        <div style="padding-left: 0;">
            <md-button style="margin-left: 0;" v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
        </div>
        <table class="data-table" style="padding: 0">
            <thead>
                <tr>
                    <th style="width:100px;">#</th>
                    <th class="left" style="width:20%;">Vendor</th>
                    <th class="left" style="width:20%;">Mã phiếu</th>
                    <th class="right" style="width:15%;">Tổng tiền</th>
                    <th class="center" style="width:15%;">Ngày tạo</th>
                    <th class="left" style="width:15%;">Người tạo</th>
                    <th style="width:10%;">Hành động</th>
                </tr>
            </thead>
            <tbody v-if="loadding == true" style="height: 150px;">
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length > 0">
                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                    <td class="center">{{index + 1}}</td>
                    <td class="left">{{item.company.companyAlias}}</td>
                    <td class="left">{{item.refundCode}}</td>
                    <td class="right">{{ formatNumber(item.pay)}}</td>
                    <td class="center">{{item.refundDate}}</td>
                    <td class="left">{{item.staff.fullName}}</td>
                    <td class="action">
                        <md-button @click="edit(item.id)" class="md-fab md-mini md-second">
                            <md-icon>edit</md-icon>
                            <md-tooltip>Sửa</md-tooltip>
                        </md-button>
                        <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                            <md-icon>clear</md-icon>
                            <md-tooltip>Xóa</md-tooltip>
                        </md-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <crudForm ref="crudForm" @refresh="refresh" />
    </div>
</template>
<script>
    import refundService from '../../../api/refundService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import crudForm from "./CrudRefund.vue"

    export default {
        props: ["order"],
        components: {
            crudForm
        },
        data() {
            return {
                loadding: false,
                data: [],
                id: 0
            }
        },
        created(){
            this.getData();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            refresh(){
                this.getData();
            },

            filter(){
                this.getData();
            },

            getData(){
                this.loadding = true;
                this.orderId = this.$route.params.id;
                refundService.getAll(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                refundService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$refs.crudForm.open(0);
            },

            edit(id){
                this.$refs.crudForm.open(id);
            },
        }
    }

</script>
