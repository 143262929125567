<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-dialog-title>{{title}}</md-dialog-title>
        <md-content class="md-scrollbar">
            <div class="group-box" style="margin-top:10px;">
                <div class="group-title">Thiết lập kiện hàng</div>
                <div class="row">
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="count">Số lượng kiện hàng</label>
                            <md-input name="count" v-model="count" type="number"></md-input>
                        </md-field>
                    </div>
                    <div class="col l-8 m-8 c-12">
                        <div class="form-control">
                            <md-field v-if="count > 1">
                                <label for="note">Danh sách mã con't/Số seal</label>
                                <md-input name="note" v-model="listSerial2" style="width:120px;"></md-input>
                            </md-field>
                            <md-button v-if="count > 1" @click="importCont()" class="md-icon-button">
                                <md-icon>add</md-icon>
                                <md-tooltip>Nhập danh sách số con't/số serial</md-tooltip>
                            </md-button>
                        </div>
                        <div class="row" v-if="count == 1">
                            <div class="col l-6 m-6 c-12">
                                <div class="form-control" style="padding-right:30px;">
                                    <md-field v-if="count == 1">
                                        <label for="note">Số cont/mã kiện</label>
                                        <md-input name="note" v-model="entity.serial"></md-input>
                                    </md-field>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <md-field v-if="count == 1">
                                    <label for="note">Số seal</label>
                                    <md-input name="note" v-model="entity.seal"></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="group-box" style="margin-top:20px;">
                <div class="group-title">Thông tin kiện hàng</div>
                <div class="row">
                    <div class="col l-4 m-4 c-12">
                        <div class="form-control">
                            <md-field :class="{'md-invalid': submitted && $v.entity.typeOfTransport.$error }">
                                <label>Loại hình<span class="label-require">(*)</span></label>
                                <md-select v-model="entity.typeOfTransport">
                                    <md-option v-for="item in typeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                </md-select>
                                <span class="md-error" v-if="submitted && !$v.entity.typeOfTransport.isSelected">Vui lòng chọn loại hình vận chuyển</span>
                            </md-field>
                        </div>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <div class="form-control">
                            <md-field :class="{'md-invalid': submitted && $v.entity.goodsTypeId.$error }">
                                <label>Loại hàng<span class="label-require">(*)</span></label>
                                <md-select v-model="entity.goodsTypeId">
                                    <md-option v-for="item in goodsTypeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                </md-select>
                                <span class="md-error" v-if="submitted && !$v.entity.goodsTypeId.isSelected">Vui lòng chọn hàng</span>
                            </md-field>
                        </div>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <div class="form-control">
                            <md-autocomplete v-model="unitName" @md-selected="getUnitSelected" :md-options="units" @md-changed="getUnits"  @md-opened="getUnits" :class="{'md-invalid': submitted && $v.entity.unitId.$error }">
                                <label>Đơn vị<span class="label-require">(*)</span></label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                    <md-highlight-text :md-term="term">{{ item.unitName }}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                    "{{ term }}" Không tìm thấy!
                                </template>
                                <span class="md-error" v-if="submitted && !$v.entity.unitId.isSelected">Vui lòng chọn đợn vị hàng hóa</span>
                            </md-autocomplete>
                            <md-button @click="openUnit()" class="md-icon-button">
                                <md-icon>manage_search</md-icon>
                            </md-button>
                        </div>  
                    </div>
                </div>
                <div class="row">
                    <div class="col l-4 m-4 c-12">
                        <div class="form-control">
                            <md-autocomplete v-model="goodsName" @md-selected="getGoodsSelected" :md-options="goods" @md-changed="getGoods"  @md-opened="getGoods" :class="{'md-invalid': submitted && $v.entity.goodsId.$error }">
                                <label>Hàng hóa<span class="label-require">(*)</span></label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                    <md-highlight-text :md-term="term">{{ item.goodsName }}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                    "{{ term }}" Không tìm thấy!
                                </template>
                                <span class="md-error" v-if="submitted && !$v.entity.goodsId.isSelected">Vui lòng chọn hàng hóa</span>
                            </md-autocomplete>
                            <md-button @click="openGoods()" class="md-icon-button">
                                <md-icon>manage_search</md-icon>
                                <md-tooltip>Tìm hàng hóa</md-tooltip>
                            </md-button>
                        </div>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <div>Khối lượng(kg)</div>
                        <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                            <number name="salarybase" v-model="entity.kg" v-bind="number"></number> 
                        </md-field>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <div>Số khối</div>
                        <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                            <number name="salarybase" v-model="entity.volume" v-bind="number" style="padding: 3px 0"></number> 
                        </md-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col l-4 m-4 c-12">
                        <md-datepicker v-model="entity.emptyReturn" md-immediately :md-model-type="String">
                            <label>Hạn rỗng</label>
                        </md-datepicker>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-datepicker v-model="entity.dateTakeCont" md-immediately :md-model-type="String">
                            <label>Ngày lấy cont</label>
                        </md-datepicker>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="note">Ghi chú</label>
                            <md-input name="note" v-model="entity.note"></md-input>
                        </md-field>
                    </div>
                </div>
                <div v-if="entity.typeOfTransport == 1">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="entity.dateOfReceipt" md-immediately :md-model-type="String">
                                <label>Ngày giao/nhận</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfReceiptContName" @md-selected="getPlaceOfReceiptContSelected" :md-options="placeOfReceiptContList" @md-changed="getPlaceOfReceiptContList"  @md-opened="getPlaceOfReceiptContList">
                                    <label>Nơi lấy con't rỗng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfReceiptCont()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfReceiptGoodsName" @md-selected="getPlaceOfReceiptGoodsSelected" :md-options="placeOfReceiptGoodsList" @md-changed="getPlaceOfReceiptGoodsList"  @md-opened="getPlaceOfReceiptGoodsList">
                                    <label>Nơi nhận/đóng hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfReceiptGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="transitDeliveryName" @md-selected="getTransitDeliverySelected" :md-options="transitDeliveryList" @md-changed="getTransitDeliveryList"  @md-opened="getTransitDeliveryList">
                                    <label>Cảng đi/hạ công</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openTransitDelivery()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="entity.typeOfTransport == 2">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="entity.dateOfReceipt" md-immediately :md-model-type="String">
                                <label>Ngày giao/nhận</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="transitReceiptName" @md-selected="getTransitReceiptSelected" :md-options="transitReceiptList" @md-changed="getTransitReceiptList"  @md-opened="getTransitReceiptList">
                                    <label>Cảng đến/nhận con't</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openTransitReceipt()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfDeliveryGoodsName" @md-selected="getPlaceOfDeliveryGoodsSelected" :md-options="placeOfDeliveryGoodsList" @md-changed="getPlaceOfDeliveryGoodsList"  @md-opened="getPlaceOfDeliveryGoodsList">
                                    <label>Nơi trả hàng/trả con't</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfDeliveryGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfDeliveryContName" @md-selected="getPlaceOfDeliveryContSelected" :md-options="placeOfDeliveryContList" @md-changed="getPlaceOfDeliveryContList"  @md-opened="getPlaceOfDeliveryContList">
                                    <label>Nơi trả con't rỗng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfDeliveryCont()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="entity.typeOfTransport == 3">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-datepicker v-model="entity.dateOfReceipt" md-immediately :md-model-type="String">
                                <label>Ngày đóng hàng</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-datepicker v-model="entity.dateOfDelivery" md-immediately :md-model-type="String">
                                <label>Ngày rút hàng</label>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfReceiptContName" @md-selected="getPlaceOfReceiptContSelected" :md-options="placeOfReceiptContList" @md-changed="getPlaceOfReceiptContList"  @md-opened="getPlaceOfReceiptContList">
                                    <label>Nơi lấy con't rỗng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfReceiptCont()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfReceiptGoodsName" @md-selected="getPlaceOfReceiptGoodsSelected" :md-options="placeOfReceiptGoodsList" @md-changed="getPlaceOfReceiptGoodsList"  @md-opened="getPlaceOfReceiptGoodsList">
                                    <label>Nơi nhận/đóng hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfReceiptGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="transitDeliveryName" @md-selected="getTransitDeliverySelected" :md-options="transitDeliveryList" @md-changed="getTransitDeliveryList"  @md-opened="getTransitDeliveryList">
                                    <label>Cảng đi</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openTransitDelivery()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="transitReceiptName" @md-selected="getTransitReceiptSelected" :md-options="transitReceiptList" @md-changed="getTransitReceiptList"  @md-opened="getTransitReceiptList">
                                    <label>Cảng đến</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openTransitReceipt()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfDeliveryGoodsName" @md-selected="getPlaceOfDeliveryGoodsSelected" :md-options="placeOfDeliveryGoodsList" @md-changed="getPlaceOfDeliveryGoodsList"  @md-opened="getPlaceOfDeliveryGoodsList">
                                    <label>Nơi trả hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfDeliveryGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfDeliveryContName" @md-selected="getPlaceOfDeliveryContSelected" :md-options="placeOfDeliveryContList" @md-changed="getPlaceOfDeliveryContList"  @md-opened="getPlaceOfDeliveryContList">
                                    <label>Nơi trả con't rỗng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfDeliveryCont()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="entity.typeOfTransport == 4">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.dateOfReceipt" md-immediately :md-model-type="String">
                                <label>Ngày giao/nhận</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="transitDeliveryName" @md-selected="getTransitDeliverySelected" :md-options="transitDeliveryList" @md-changed="getTransitDeliveryList"  @md-opened="getTransitDeliveryList">
                                    <label>Cảng đi</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openTransitDelivery()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="transitReceiptName" @md-selected="getTransitReceiptSelected" :md-options="transitReceiptList" @md-changed="getTransitReceiptList"  @md-opened="getTransitReceiptList">
                                    <label>Cảng đến</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openTransitReceipt()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="entity.typeOfTransport == 5">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.dateOfReceipt" md-immediately :md-model-type="String">
                                <label>Ngày giao/nhận</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfReceiptGoodsName" @md-selected="getPlaceOfReceiptGoodsSelected" :md-options="placeOfReceiptGoodsList" @md-changed="getPlaceOfReceiptGoodsList"  @md-opened="getPlaceOfReceiptGoodsList">
                                    <label>Nơi nhận/đóng hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfReceiptGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfDeliveryGoodsName" @md-selected="getPlaceOfDeliveryGoodsSelected" :md-options="placeOfDeliveryGoodsList" @md-changed="getPlaceOfDeliveryGoodsList"  @md-opened="getPlaceOfDeliveryGoodsList">
                                    <label>Nơi trả hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfDeliveryGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="entity.typeOfTransport == 6">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.dateOfReceipt" md-immediately :md-model-type="String">
                                <label>Ngày giao/nhận</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfReceiptContName" @md-selected="getPlaceOfReceiptContSelected" :md-options="placeOfReceiptContList" @md-changed="getPlaceOfReceiptContList"  @md-opened="getPlaceOfReceiptContList">
                                    <label>Nơi lấy con't rỗng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfReceiptCont()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="placeOfDeliveryContName" @md-selected="getPlaceOfDeliveryContSelected" :md-options="placeOfDeliveryContList" @md-changed="getPlaceOfDeliveryContList"  @md-opened="getPlaceOfDeliveryContList">
                                    <label>Nơi trả con't rỗng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openPlaceOfDeliveryCont()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-content>
        <md-dialog-actions>
            <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
            <md-button class="md-primary" @click="submit()">Lưu</md-button>
            <md-button class="md-primary" @click="close()">Đóng</md-button>
        </md-dialog-actions>

        <goodsList ref="goodsList" @close="closeGoods"/>
        <locationReceiptCont ref="locationReceiptCont" @close="closePlaceOfReceiptCont"/>
        <locationDeliveryCont ref="locationDeliveryCont" @close="closePlaceOfDeliveryCont"/>
        <locationReceiptGoods ref="locationReceiptGoods" @close="closePlaceOfReceiptGoods"/>
        <locationDeliveryGoods ref="locationDeliveryGoods" @close="closePlaceOfDeliveryGoods"/>
        <locationTransitReceipt ref="locationTransitReceipt" @close="closeTransitReceipt"/>
        <locationTransitDelivery ref="locationTransitDelivery" @close="closeTransitDelivery"/>
        <unitList ref="unitList" @close="closeUnit"/>

        <md-dialog :md-active.sync="showSerialDialog">
            <md-content class="dialog-serial">
                <div class="dialog-serial-content">
                    <div>
                        <md-field>
                            <label>Danh sách số con't/kiện hàng</label>
                            <md-textarea v-model="listSerial"></md-textarea>
                        </md-field>
                    </div>
                    <div>
                        <md-field>
                            <label>Danh sách số seal</label>
                            <md-textarea v-model="listSeal"></md-textarea>
                        </md-field>
                    </div>
                </div>
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="showSerialDialog = false">Đóng</md-button>
                <md-button class="md-primary" @click="addSerial()">OK</md-button>
            </md-dialog-actions>
        </md-dialog>
    </md-dialog>
</template>

<script>
    import goodsService from '../../../api/goodsService';
    import orderService from '../../../api/orderService';
    import unitService from '../../../api/unitService';
    import locationService from '../../../api/locationService';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import messageBox from '../../../utils/messageBox';
    import goodsList from '../../../components/popup/_GoodsList.vue';
    import locationReceiptCont from '../../../components/popup/_LocationList.vue';
    import locationDeliveryCont from '../../../components/popup/_LocationList.vue';
    import locationReceiptGoods from '../../../components/popup/_LocationList.vue';
    import locationDeliveryGoods from '../../../components/popup/_LocationList.vue';
    import locationTransitReceipt from '../../../components/popup/_LocationList.vue';
    import locationTransitDelivery from '../../../components/popup/_LocationList.vue';
    import unitList from '../../../components/popup/_UnitList.vue';
    
    export default ({
        components: {
            goodsList,
            locationReceiptCont,
            locationDeliveryCont,
            locationReceiptGoods,
            locationDeliveryGoods,
            locationTransitReceipt,
            locationTransitDelivery,
            unitList
        },
        data() {
            return {
                title: '',
                saveAndCreate: false,
                id:0,
                showDialog: false,
                submitted: false,
                goodsTypeList: common.goodsTypeList,
                typeList: common.typeOfTransport,
                entity: {id: '',  emptyReturn: '', note: '', code: '', kg: 0, volume: 0, length: 0, width: 0, height: 0, orderId: 0, goods: {goodsName: '', goodsCode: ''}, goodsType: {title: ''}, unit: {code: '', unitName: ''}, status: 0, serial: '', seal: '', dateOfReceipt: null, dateOfDelivery: null, unitId: 0, typeOfTransport: 1, goodsTypeId: 1, goodsId: 0, transitReceipt: {}, transitDelivery: {}, placeOfReceiptGoods: {}, placeOfDeliveryGoods: {}, placeOfReceiptCont: {}, placeOfDeliveryCont: {}, transitReceiptId: null, transitDeliveryId: null, placeOfReceiptGoodsId: null, placeOfDeliveryGoodsId: null, placeOfReceiptContId: null, placeOfDeliveryContId: null},
                goodsName: '',
                goods: [],
                placeOfReceiptContName: '',
                placeOfReceiptContList: [],
                placeOfDeliveryContName: '',
                placeOfDeliveryContList: [],
                placeOfReceiptGoodsName: '',
                placeOfReceiptGoodsList: [],
                placeOfDeliveryGoodsName: '',
                placeOfDeliveryGoodsList: [],
                transitReceiptName: '',
                transitReceiptList: [],
                transitDeliveryName: '',
                transitDeliveryList: [],
                transportPlanName: '',
                transportPlans: [],
                transportTypeName: '',
                transportTypes: [],
                unitName: '',
                units: [],
                count: 1,
                showSerialDialog: false,
                listSerial: '',
                listSeal: '',
                listSerial2: '',
                ownerId: 0
            }
        },
        created(){
            if(this.id > 0){
               this.title = 'Cập nhật chi tiết đơn hàng';
            }
            else{
               this.title = 'Thêm mới chi tiết đơn hàng';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            addSerial(){
                this.listSerial2 = this.listSerial.split(/\r?\n/).toString();
                this.showSerialDialog = false;
            },

            importCont(){
                this.showSerialDialog = true;
            },

            getCode(){
                orderService.getCode().then((response) => {
                    if(response.statusCode == 200){
                        this.entity.code = response.data + '-1';
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            open(item, ownerId, typeOfTransport){
                this.ownerId = ownerId;
                this.showSerialDialog = false;
                if(item != null && item.id > 0){
                    this.saveAndCreate = false;
                    this.id = item.goodsId;
                    this.entity = item;
                    this.goodsName = item.goods.goodsName;
                    this.unitName = item.unit.unitName;

                    if(item.placeOfReceiptCont != null){
                        this.placeOfReceiptContName = item.placeOfReceiptCont.locationName;
                    }
                    if(item.placeOfDeliveryCont != null){
                        this.placeOfDeliveryContName = item.placeOfDeliveryCont.locationName;
                    }
                    if(item.placeOfReceiptGoods != null){
                        this.placeOfReceiptGoodsName = item.placeOfReceiptGoods.locationName;
                    }
                    if(item.placeOfDeliveryGoods != null){
                        this.placeOfDeliveryGoodsName = item.placeOfDeliveryGoods.locationName;
                    }
                    if(item.transitReceipt != null){
                        this.transitReceiptName = item.transitReceipt.locationName;
                    }
                    if(item.transitDelivery != null){
                        this.transitDeliveryName = item.transitDelivery.locationName;
                    }
                }
                else{
                    this.id = 0;
                    this.submitted =  false;
                    this.goodsName = '';
                    this.unitName = '';
                    this.transportPlanName = '';
                    this.transportTypeName = '';
                    this.placeOfReceiptGoodsName = '';
                    this.placeOfDeliveryGoodsName = '';
                    this.placeOfReceiptContName = '';
                    this.placeOfDeliveryContName = '';
                    this.getCode();
                    this.entity = {
                        id: 'id_' + common.getFakeId(), 
                        typeOfTransport: 1, code: '', 
                        kg: 0, volume: 0, length: 0, width: 0, 
                        height: 0, orderId: 0, goods: {goodsName: '', goodsCode: ''}, goodsType: {title: ''}, 
                        unit: {code: '', unitName: ''}, status: 0, 
                        serial: '', seal: '', dateOfReceipt: null, dateOfDelivery: null, 
                        goodsTypeId: 1, goodsId: 0, unitId: 0, quantity: 0, transitReceiptId: null, 
                        transitDeliveryId: null, placeOfReceiptGoodsId: null, placeOfDeliveryGoodsId: null, 
                        placeOfReceiptContId: null, placeOfDeliveryContId: null,
                        emptyReturn: '', note: '', dateTakeCont: ''
                    };
                    if(typeOfTransport > 0){
                        this.entity.typeOfTransport = typeOfTransport;
                    }
                }
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                let list = [];
                let obj = this.entity;
                if(this.count > 1){
                    if(this.listSerial !== '' && this.listSerial !== null){
                        let result = this.listSerial.split(/\r?\n/);
                        let result2 = this.listSeal.split(/\r?\n/);
                        for(let i = 0; i < result.length; i ++){
                            let seal = '';
                            if(result2[i] !== '' && result2[i] !== undefined){
                                seal = result2[i];
                            }
                            if(result[i] !== ''){
                                let item = {id: this.uuidv4(i), dateTakeCont: obj.dateTakeCont, emptyReturn: obj.emptyReturn, note: obj.note, typeOfTransport: obj.typeOfTransport, serial: result[i], seal: seal, kg: obj.kg, volume: obj.volume, length: obj.length, width: obj.width, height: obj.height, orderId: obj.height, goods: obj.goods, goodsType: obj.goodsType, status: obj.status, dateOfReceipt: obj.dateOfReceipt, dateOfDelivery: obj.dateOfDelivery, unitId: obj.unitId, unit: obj.unit, goodsTypeId: obj.goodsTypeId, goodsId: obj.goodsId, transitReceiptId: obj.transitReceiptId, transitDeliveryId: obj.transitDeliveryId, placeOfReceiptGoodsId: obj.placeOfReceiptGoodsId, placeOfDeliveryGoodsId: obj.placeOfDeliveryGoodsId, placeOfReceiptContId: obj.placeOfReceiptContId, placeOfDeliveryContId: obj.placeOfDeliveryContId, transitReceipt: obj.transitReceipt, transitDelivery: obj.transitDelivery, placeOfReceiptGoods: obj.placeOfReceiptGoods, placeOfDeliveryGoods: obj.placeOfDeliveryGoods, placeOfReceiptCont: obj.placeOfReceiptCont, placeOfDeliveryCont: obj.placeOfDeliveryCont};
                                list.push(item);
                            }
                        }
                        if(this.count > result.length){
                            for(let i = result.length; i < this.count; i ++){
                                list.push(this.entity);
                            }
                        }
                    }
                    
                    else{
                        for(let i = 0; i < this.count; i ++){
                            list.push(this.entity);
                        }
                    }
                }
                else{
                    list.push(obj);
                }

                let items = [];
                for(let i = 0; i < list.length; i++){
                    let obj = list[i];
                    let item = {id: this.uuidv4(i), emptyReturn: obj.emptyReturn, note: obj.note, typeOfTransport: obj.typeOfTransport, serial: obj.serial, seal: obj.seal, kg: obj.kg, volume: obj.volume, length: obj.length, width: obj.width, height: obj.height, orderId: obj.height, goods: obj.goods, goodsType: obj.goodsType, status: obj.status, dateOfReceipt: obj.dateOfReceipt, dateTakeCont: obj.dateTakeCont, dateOfDelivery: obj.dateOfDelivery, unitId: obj.unitId, unit: obj.unit, goodsTypeId: obj.goodsTypeId, goodsId: obj.goodsId, transitReceiptId: obj.transitReceiptId, transitDeliveryId: obj.transitDeliveryId, placeOfReceiptGoodsId: obj.placeOfReceiptGoodsId, placeOfDeliveryGoodsId: obj.placeOfDeliveryGoodsId, placeOfReceiptContId: obj.placeOfReceiptContId, placeOfDeliveryContId: obj.placeOfDeliveryContId, transitReceipt: obj.transitReceipt, transitDelivery: obj.transitDelivery, placeOfReceiptGoods: obj.placeOfReceiptGoods, placeOfDeliveryGoods: obj.placeOfDeliveryGoods, placeOfReceiptCont: obj.placeOfReceiptCont, placeOfDeliveryCont: obj.placeOfDeliveryCont};
                    items.push(item);
                }
                
                this.$emit('close', items);
                if(!this.saveAndCreate){
                    this.showDialog = false;
                }
                this.id = 0;
                this.submitted =  false;
            },

            uuidv4(id) {
                let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
                );

                return uuid + "-" + id;
            },

            //TransitDelivery
            closeTransitDelivery(item){
                this.transitDeliveryName = item.locationName;
                this.entity.transitDeliveryId = item.id;
                this.entity.transitDelivery = item;
                this.$refs.locationTransitDelivery.close();
            },

            openTransitDelivery(){
                this.$refs.locationTransitDelivery.open(0);
            },

            getTransitDeliverySelected(val){
                this.entity.transitDeliveryId = val.id;
                this.entity.transitDelivery = val;
                this.transitDeliveryName = val.locationName;
            },
            
            getTransitDeliveryList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.transitDeliveryList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //TransitReceipt
            closeTransitReceipt(item){
                this.transitReceiptName = item.locationName;
                this.entity.transitReceiptId = item.id;
                this.entity.transitReceipt = item;
                this.$refs.locationTransitReceipt.close();
            },

            openTransitReceipt(){
                this.$refs.locationTransitReceipt.open(0);
            },

            getTransitReceiptSelected(val){
                this.entity.transitReceiptId = val.id;
                this.transitReceiptName = val.locationName;
                this.entity.transitReceipt = val;
            },
            
            getTransitReceiptList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.transitReceiptList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //PlaceOfReceiptGoods
            closePlaceOfReceiptGoods(item){
                this.placeOfReceiptGoodsName = item.locationName;
                this.entity.placeOfReceiptGoodsId = item.id;
                this.entity.placeOfReceiptGoods = item;
                this.$refs.locationReceiptGoods.close();
            },

            openPlaceOfReceiptGoods(){
                this.$refs.locationReceiptGoods.open(this.ownerId);
            },

            getPlaceOfReceiptGoodsSelected(val){
                this.entity.placeOfReceiptGoodsId = val.id;
                this.entity.placeOfReceiptGoods = val;
                this.placeOfReceiptGoodsName = val.locationName;
            },
            
            getPlaceOfReceiptGoodsList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, categoryId: 0, companyId: this.ownerId, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.placeOfReceiptGoodsList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //PlaceOfDeliveryGoods
            closePlaceOfDeliveryGoods(item){
                this.placeOfDeliveryGoodsName = item.locationName;
                this.entity.placeOfDeliveryGoodsId = item.id;
                this.entity.placeOfDeliveryGoods = item;
                this.$refs.locationDeliveryGoods.close();
            },
            
            openPlaceOfDeliveryGoods(){
                this.$refs.locationDeliveryGoods.open(this.ownerId);
            },

            getPlaceOfDeliveryGoodsSelected(val){
                this.entity.placeOfDeliveryGoodsId = val.id;
                this.placeOfDeliveryGoodsName = val.locationName;
                 this.entity.placeOfDeliveryGoods = val;
            },

            getPlaceOfDeliveryGoodsList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, categoryId: 0, companyId: this.ownerId, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.placeOfDeliveryGoodsList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //PlaceOfDeliveryCont
            closePlaceOfDeliveryCont(item){
                this.placeOfDeliveryContName = item.locationName;
                this.entity.placeOfDeliveryContId = item.id;
                this.entity.placeOfDeliveryCont = item;
                this.$refs.locationDeliveryCont.close();
            },

            openPlaceOfDeliveryCont(){
                this.$refs.locationDeliveryCont.open(0);
            },

            getPlaceOfDeliveryContSelected(val){
                this.entity.placeOfDeliveryContId = val.id;
                this.placeOfDeliveryContName = val.locationName;
                this.entity.placeOfDeliveryCont = val;
            },

            getPlaceOfDeliveryContList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 3, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.placeOfDeliveryContList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //PlaceOfReceiptCont
            closePlaceOfReceiptCont(item){
                this.placeOfReceiptContName = item.locationName;
                this.entity.placeOfReceiptContId = item.id;
                this.entity.placeOfReceiptCont = item;
                this.$refs.locationReceiptCont.close();
            },

            openPlaceOfReceiptCont(){
                this.$refs.locationReceiptCont.open(0);
            },

            getPlaceOfReceiptContSelected(val){
                this.entity.placeOfReceiptContId = val.id;
                this.placeOfReceiptContName = val.locationName;
                this.entity.placeOfReceiptCont = val;
            },

            getPlaceOfReceiptContList(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 3, code:  searchTerm };
                locationService.getLocations(search).then((response) => {
                    if(response.statusCode == 200){
                        this.placeOfReceiptContList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Goods
            closeGoods(item){
                this.goodsName = item.goodsName;
                this.entity.goods = item;
                this.entity.goodsId = item.id;
                this.$refs.goodsList.close();
            },

            getGoodsSelected(val){
                this.entity.goods = val;
                this.entity.goodsId = val.id;
                this.goodsName = val.goodsName;
            },

            openGoods(){
                this.$refs.goodsList.open(1);
            },

            getGoods(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 1, code:  searchTerm };
                goodsService.getGoodss(search).then((response) => {
                    if(response.statusCode == 200){
                        this.goods = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Unit
            closeUnit(item){
                this.entity.unit = item;
                this.unitName = item.unitName;
                this.entity.unitId = item.id;
                this.$refs.unitList.close();
            },

            getUnitSelected(val){
                this.entity.unitId = val.id;
                this.unitName = val.unitName;
                this.entity.unit = val;
            },

            openUnit(){
                this.$refs.unitList.open();
            },

            getUnits(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                unitService.getUnits(search).then((response) => {
                    if(response.statusCode == 200){
                        this.units = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: { 
            goodsName: function (val) { 
                if(val == ''){
                    this.entity.goodsId = 0;
                }
            },
            unitName: function (val) { 
                if(val == ''){
                    this.entity.unitId = 0;
                }
            },
        },
        validations: {
            entity: {
                goodsId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                typeOfTransport: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                unitId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                goodsTypeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
            }
        }
     })

</script>

<style lang="css" scoped>
    .dialog-serial {
        padding-top: 20px !important;
        width: 550px !important;
        height: 250px !important;
    }
    .dialog-serial-content {
        display: flex;
        justify-content: space-between;
    }
    .md-textarea {
        height: 190px;
        width: 250px;
    }
    .md-content {
        max-width: 1100px;
        width: 1100px;
        max-height: auto;
        height: auto;
        overflow-y: auto;
        padding: 0 15px;
    }
    .dialog-form{
        padding: 15px;
    }
    .md-dialog-title {
        padding: 10px 24px 0 !important;
        margin-bottom: 10px !important;
    }
</style>
