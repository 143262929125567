<template>
    <div class="table-content" style="padding: 0">
        <div style="padding-left: 0;">
            <md-button style="margin-left: 0;" v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
        </div>
        <table class="data-table" style="padding: 0">
            <thead>
                <tr>
                    <th style="width:100px;">#</th>
                    <th style="width:10%;">Người dự toán</th>
                    <th style="width:15%;">Mã dự toán</th>
                    <th style="width:10%;">Tổng dự toán</th>
                    <th style="width:10%;">Ngày dự toán</th>
                    <th style="width:10%;">Loại dự toán</th>
                    <th style="width:10%;">Tạm ứng</th>
                    <th style="width:10%;">Giải chi</th>
                    <th style="width:10%;">Quyết toán</th>
                    <th style="width:10%;">Hành động</th>
                </tr>
            </thead>
            <tbody v-if="loadding == true" style="height: 150px;">
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length > 0">
                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                    <td class="center">{{index + 1}}</td>
                    <td class="center">{{item.staff.fullName}}</td>
                    <td class="center">{{item.estimateCode}}</td>
                    <td class="center">{{ formatNumber(item.pay)}}</td>
                    <td class="center">{{item.estimateDate}}</td>
                    <td class="center">
                        <md-chip v-if="item.paymentType == 1" class="md-primary">Tạm ứng</md-chip>
                        <md-chip v-else-if="item.paymentType == 2" style="background-color: gold;">Thanh toán đối tác</md-chip>
                        <md-chip v-else style="background-color: burlywood;">Thanh toán nhân viên</md-chip>
                    </td>
                    <td class="center">
                        <md-chip  v-if="item.isAdvancePayment && item.paymentType == 1" class="md-primary">Đã tạm ứng</md-chip>
                        <md-chip v-else-if="item.paymentType == 1 && !item.isAdvancePayment">Chưa tạm ứng</md-chip>
                        <span v-else>N/A</span>
                    </td>
                    <td class="center">
                        <md-chip  v-if="item.isExplain && item.paymentType == 1" class="md-primary">Đã giải chi</md-chip>
                        <md-chip v-else-if="item.paymentType == 1 && !item.isExplain">Chưa giải chi</md-chip>
                        <span v-else>N/A</span>
                    </td>
                    <td class="center">
                        <md-chip  v-if="item.isInvoice" class="md-primary">Đã QT</md-chip>
                        <md-chip v-else>Chưa QT</md-chip>
                    </td>
                    <td class="action">
                        <md-button v-if="item.paymentType == 1 && item.isAdvancePayment && item.isExplain == false" @click="explain(item.id)" class="md-fab md-mini md-view">
                            <i class="uil uil-usd-circle"></i>
                            <md-tooltip>Giải chi</md-tooltip>
                        </md-button>
                        <md-button v-else disabled class="md-fab md-mini md-plain">
                            <i class="uil uil-usd-circle"></i>
                            <md-tooltip>Giải chi</md-tooltip>
                        </md-button>
                        <md-button v-if="item.paymentType !== 1" @click="invoice(item.id)" class="md-fab md-mini md-view">
                            <i class="uil uil-usd-circle"></i>
                            <md-tooltip>Đề nghị thanh toán</md-tooltip>
                        </md-button>
                        <md-button @click="view(item.id)" class="md-fab md-mini md-view">
                            <i class="uil uil-eye"></i>
                            <md-tooltip>Xem</md-tooltip>
                        </md-button>
                        <md-button v-if="item.isAdvancePayment == false && item.isExplain == false && item.isInvoice == false" @click="edit(item.id)" class="md-fab md-mini md-second">
                            <md-icon>edit</md-icon>
                            <md-tooltip>Sửa</md-tooltip>
                        </md-button>
                        <md-button v-else disabled class="md-fab md-mini md-plain">
                            <md-icon>edit</md-icon>
                            <md-tooltip>Sửa</md-tooltip>
                        </md-button>
                        <md-button v-if="item.isAdvancePayment == false && item.isExplain == false && item.isInvoice == false" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                            <md-icon>clear</md-icon>
                            <md-tooltip>Xóa</md-tooltip>
                        </md-button>
                        <md-button v-else disabled class="md-fab md-mini md-plain">
                            <md-icon>clear</md-icon>
                            <md-tooltip>Xóa</md-tooltip>
                        </md-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <crudForm ref="crudForm" @refresh="refresh" />
        <explainForm ref="explainForm" @refresh="refresh" />
        <crudInvoice ref="crudInvoice" @refresh="refresh" />
    </div>
</template>
<script>
    import orderEstimateService from '../../../api/orderEstimateService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import crudForm from "./CrudEstimate.vue"
    import explainForm from "./ExplainEstimate.vue"
    import crudInvoice from "./CrudInvoice.vue"

    export default {
        props: ["order"],
        components: {
            crudForm,
            explainForm,
            crudInvoice
        },
        metaInfo: {
            title: 'Danh sách dự toán chi phí làm hàng'
        },
        data() {
            return {
                loadding: false,
                data: [],
                id: 0
            }
        },
        created(){
            this.getData();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            refresh(){
                this.getData();
            },

            filter(){
                this.getData();
            },

            getData(){
                this.loadding = true;
                this.orderId = this.$route.params.id;
                orderEstimateService.getAll(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                orderEstimateService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$refs.crudForm.open(0, this.order);
            },

            edit(id){
                this.$refs.crudForm.open(id, this.order);
            },

            explain(id){
                this.$refs.explainForm.open(id, this.order);
            },
            
            invoice(id){
                this.$refs.crudInvoice.open(id);
            },

            view(id){
                this.$router.push('/order-estimate/view/' + id);
            }
        }
    }

</script>
